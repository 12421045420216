export default {
  shop_center_txt: "ศูนย์การค้า",
  score_txt: "คะแนน",
  minute_txt: "นาที",
  wallet_txt: "กระเป๋าสตางค์",
  today_txt: "วันนี้",
  week_txt: "สัปดาห์นี้",
  month_txt: "เดือนนี้",
  year_txt: "ทั้งปี",
  main_indicators_txt: "ตัวชี้วัดหลัก",
  sale_price: "ราคาขาย",
  expected_profit: "กำไรที่คาดหวัง",
  order_count: "ปริมาณการสั่งซื้อ",
  goods_click_count: "จำนวนการคลิกผลิตภัณฑ์",
  supplier_txt: "ซัพพลายเออร์",
  friends_help_txt: "เพื่อนช่วย",
  feedback_txt: "คำติชม",
  feedback: "ข้อเสนอแนะ",
  online_service_txt_1: "บริการลูกค้าเงินฝาก",
  online_service_txt_2: "การให้คำปรึกษาทางธุรกิจ",
  q_and_a_txt: "คำถามและคำตอบ",
  open_now_txt: "เปิด",
  time_txt: "เวลา",
  times_txt: "เวลา",

  wallet_total_assets: "สินทรัพย์ทั้งหมด",
  balance_txt: "สมดุล",
  pending_settlement_txt: "รอการชำระหนี้",
  recharge_txt: "เติมเงิน",
  withdraw_txt: "ถอนออก",
  fund_details_txt: "รายละเอียดกองทุน",
  no_more_txt: "ไม่มีอีกแล้ว",
  no_data_txt: "ยังไม่มีข้อมูล",
  detail_txt: "รายละเอียด",
  num_txt: "หมายเลข",
  money_txt: "จำนวนเงิน",
  remark_txt: "หมายเหตุ",
  no_remark_txt: "ไม่มีหมายเหตุ",
  tip_txt: "เคล็ดลับ",
  tip_content_txt:
    "เนื่องจากปัญหาด้านสกุลเงินที่จัดทำโดยผู้ขายในประเทศต่างๆ ในปัจจุบัน",
  need_contact_service: "จำเป็นต้องติดต่อฝ่ายบริการลูกค้า",
  contact_service: "ติดต่อฝ่ายบริการลูกค้า",

  feedback_title: "ตอบกลับความคิดเห็นของคุณ",
  feedback_placeholder: "ด้วยเหตุผลด้านความปลอดภัย โปรดอย่าใส่ข้อมูลส่วนบุคคล",
  submit_txt: "ส่ง",
  sure_txt: "ตกลง",
  submit_suc_txt: "ส่งสำเร็จ",

  loading: "กำลังโหลด...",

  newer_action: "กิจกรรมของผู้มาใหม่",
  newer_traffic_package: "แพ็คเกจการรับส่งข้อมูลการสนับสนุนผู้มาใหม่",
  newer_get_millions_of_products: "รับผลิตภัณฑ์นับล้าน",
  newer_get: "ได้รับ",
  newer_receive: "รับ",
  newer_action_ruler: "กฎกิจกรรมของผู้มาใหม่",
  newer_receive_suc: "ได้รับเรียบร้อยแล้ว",

  help_friends_help: "เพื่อนช่วย",
  help_your_friends_stores: "ช่วยเหลือเพื่อนของคุณ",
  get_store_product_heating: "รับการทำความร้อนผลิตภัณฑ์จากร้านค้า",
  help_friends_txt: "ช่วยเหลือเพื่อน",
  help_friends_activity_rules: "กฎกิจกรรม",
  assistance_details: "รายละเอียดความช่วยเหลือ",
  assistance_details_other: "รายละเอียดของความช่วยเหลือ",
  team_list: "รายชื่อทีม",
  help_friend_name: "ช่วยชื่อเพื่อน",
  help_friend_invite_code: "ช่วยเหลือรหัสเชิญเพื่อน",
  mine_invite_code: "รหัสคำเชิญของฉัน",
  plz_input: "กรุณาระบุ",
  plz_input_friend_name: "โปรดป้อนชื่อเพื่อนของคุณเพื่อช่วยเหลือ",
  help_suc: "ช่วยให้ประสบความสำเร็จ",

  online_service: "บริการลูกค้าออนไลน์",
  online_24_hour_service: "สนับสนุนออนไลน์ตลอด 24 ชั่วโมง",
  customer_service: "บริการลูกค้า",
  copy_and_add_id: "คัดลอกและเพิ่ม LINE ID",
  plz_input_question_detail: "กรุณากรอกรายละเอียดคำถามของคุณ",
  harry_reply_your: "เราจะตอบกลับคุณโดยเร็วที่สุด",
  click_copy: "คลิกเพื่อคัดลอก",
  copy_suc: "คัดลอกสำเร็จ",
  copy_fail: "การคัดลอกล้มเหลว",

  overall: "ทั้งหมด",
  cooperate: "ร่วมมือ",
  cancel: "ยกเลิก",
  supplier_num: "หมายเลขซัพพลายเออร์",
  no_use_supplier: "ไม่มีซัพพลายเออร์ที่มีอยู่",
  here_add_supplier: "เพิ่มซัพพลายเออร์ใหม่ที่นี่",
  add_supplier: "เพิ่มซัพพลายเออร์",
  plz_input_supplier_num: "โปรดป้อนหมายเลขซัพพลายเออร์",
  add_suc: "เพิ่มเรียบร้อยแล้ว",
  remove_suc: "ลบออกเรียบร้อยแล้ว",

  new_products_launched: "เปิดตัวผลิตภัณฑ์ใหม่",
  profit_txt: "กำไร",
  has_been_added: "อยู่บนชั้นวางแล้ว",
  on_display: "สามารถวางบนชั้นวางได้",
  coming_soon_txt: "วางจำหน่ายแล้ว",
  sold_txt: "ขายแล้ว",
  removed_txt: "ลบแล้ว",

  order_detail_txt: "รายละเอียดการสั่งซื้อ",
  waiting_express_delivery: "กำลังรอการจัดส่งด่วน",
  customer_info: "ข้อมูลลูกค้า",
  order_good: "สั่งซื้อสินค้า",
  order_num: "หมายเลขคำสั่งซื้อ",
  order_date: "วันที่สั่งซื้อ",
  order_content: "สรุปคำสั่งซื้อ",
  purchase_price_txt: "ราคาซื้อ",
  income_txt: "รายได้",

  order_txt: "สั่งซื้อ",
  pending_payment: "รอดำเนินการชำระเงิน",
  ready_for_shipment: "กำลังจะถูกจัดส่ง",
  shipped_txt: "จัดส่งแล้ว",
  paid_txt: "จัดส่งแล้ว",
  good_num: "หมายเลขรายการ",
  pay_and_ship: "การชำระเงินและการจัดส่ง",
  pay_and_ship_all: "จ่ายทั้งหมด",
  pay_suc: "การชำระเงินสำเร็จ",
  no_order: "ยังไม่มีคำสั่งซื้อ",
  no_order_tips: "คุณสามารถติดตามคำสั่งซื้อและการคืนสินค้าได้ที่นี่",
  suer_order: "ยืนยันการชำระเงิน",
  pay_way: "วิธีการชำระเงิน",
  wallet_pay: "การชำระเงินด้วยกระเป๋าเงิน",
  sure_pay: "ยืนยันการชำระเงิน",
  paying_txt: "กำลังชำระเงิน...",

  manage_showcase: "จัดการตู้โชว์",
  add_new_good: "เพิ่มผลิตภัณฑ์ใหม่",
  heating_txt: "เครื่องทำความร้อน",
  promotion: "โปรโมชั่น",
  promotion_tip: "เคล็ดลับการส่งเสริมการขาย",
  promotion_tip_txt:
    "คะแนนปัจจุบันของคุณน้อยกว่า 200 และคุณไม่สามารถชำระค่าผลิตภัณฑ์ของคุณได้",
  copy_link: "คัดลอกลิงก์",
  scan_qr_code: "สแกนโค้ด QR",

  about_good: "เกี่ยวกับผลิตภัณฑ์",
  specification: "ข้อกำหนด",
  product_overview: "ภาพรวมผลิตภัณฑ์",

  top_tips_1: "พลิกโฉมร้านค้า Douyin ออนไลน์",
  top_tips_2: "ปัจจุบันรองรับเฉพาะคำเชิญภายในเท่านั้น",

  certification_activation_code: "รหัสคำเชิญ",
  plz_write_invite_code: "กรุณากรอกรหัสเชิญ",
  active_user_email: "อีเมล",
  plz_input_active_user_email: "กรุณากรอกอีเมลของคุณ",
  active_user_email_code: "รหัสยืนยันอีเมล",
  plz_input_active_user_email_code: "กรุณากรอกรหัสยืนยันอีเมล",
  active_get_email_code_txt: "รับรหัสยืนยัน",
  active_user_name: "ชื่อ",
  plz_input_active_user_name: "กรุณากรอกชื่อของคุณ",
  active_user_id: "หมายเลขบัตรประจำตัวประชาชน/หนังสือเดินทาง",
  plz_input_active_user_id:
    "กรุณากรอกหมายเลขบัตรประจำตัวประชาชน/หนังสือเดินทางของคุณ",
  upload_active_user_id: "การอัปโหลดใบรับรอง",
  plz_upload_user_id_img:
    "โปรดอัปโหลดภาพถ่ายด้านหน้าบัตรประจำตัวประชาชน/หน้าหนังสือเดินทางของคุณ",

  promotion_01: "โปรโมชั่น",
  promotion_02: "จำนวนโปรโมชั่น",
  promotion_03: "กรุณากรอกจำนวนโปรโมชั่น",
  promotion_04: "ยืนยันการส่งเสริมการขาย",
  promotion_05: "การโปรโมตสำเร็จ",

  bao_txt: "อัตราการสัมผัส",
  bal_no: "ยอดคงเหลือไม่เพียงพอ",

  wallet_type_01: "แอปพลิเคชันเติมเงิน",
  wallet_type_02: "การสมัครถอนเงิน",
  wallet_type_03: "การชำระเงิน",
  wallet_type_04: "การหักเงินจากระบบ",
  wallet_type_05: "รายได้กำไร",
  wallet_type_06: "การหักการชำระเงิน",
  wallet_type_07: "ปริมาณการใช้การกระจาย",
  wallet_type_08: "รายได้จากโปรโมชัน",
  wallet_type_09: "เพิ่มผลตอบแทน",
  wallet_type_10: "ลงทะเบียนฟรี",
  wallet_type_11: "รางวัลมูลค่าการซื้อขาย",
  wallet_type_12: "การถอนเงินถูกปฏิเสธ",

  all_orders: "คำสั่งซื้อทั้งหมด",
  mine_orders: "คำสั่งซื้อของฉัน",
  mine_services: "บริการของฉัน",
  _tzz: "กระโดด...",

  info_01: "ชื่อ",
  info_02: "อีเมล",
  info_03: "ข้อมูลการติดต่อ",
  info_04: "เพศ",
  info_05: "เป็นความลับ",
  info_06: "ชาย",
  info_07: "เพศหญิง",
  info_08: "วันเกิด(yyyy-MM-dd)",
  info_09: "ประเทศ",
  info_10: "เมือง",
  info_11: "หมายเลขบัตร",
  info_12: "ประเภทบัตร",
  info_13: "ผู้ออกบัตร",
  info_14: "วันหมดอายุ(yyyy-MM-dd)",
  info_15: "รหัส CVV",
  info_16: "รหัสผ่านความปลอดภัย",
  info_17: "ทำซ้ำรหัสผ่านความปลอดภัย",
  info_18: "โปรดป้อนรหัสผ่านความปลอดภัยอีกครั้ง",
  info_19: "รหัสผ่านความปลอดภัยทั้งสองไม่สอดคล้องกัน",
  info_20: "บัตรประจำตัวประชาชน/หนังสือเดินทาง",
  info_21: "เลือกประเภทของภาพที่จะอัปโหลด",
  info_22: "บัตรประจำตัวประชาชน (ทั้งด้านหน้าและด้านหลัง)",
  info_23: "หนังสือเดินทาง",
  info_24: "ใบขับขี่",
  info_25: "ใบอนุญาตมีถิ่นที่อยู่",
  info_26: "ใบรับรองสุขภาพ",
  info_27: "โปรดอัปโหลดรูปถ่ายประจำตัว",
  info_28: "ส่งแล้ว โปรดรออย่างอดทน",
  info_29: "หมายเลขบัญชี",
  info_30: "ชื่อธนาคาร",

  wl_withdraw_money: "จำนวนเงินที่ถอน",
  wl_withdraw_code_201:
    "บันทึกการถอนเงินอยู่ระหว่างการตรวจสอบ โปรดอย่าส่งอีกครั้ง",
  wl_withdraw_code_202: "ข้อมูลบัตรธนาคารไม่ถูกต้อง",
  wl_withdraw_code_400: "ข้อยกเว้นบันทึกการถอนเงิน",
  wl_withdraw_code_204: "รหัสผ่านความปลอดภัยไม่ถูกต้อง",
  wl_withdraw_code_205: "ยอดมาร์จิ้นไม่สามารถถอนออกได้",
  wl_withdraw_code_207:
    "ระดับดาวของผู้ใช้ปัจจุบันไม่สามารถถอนออกได้ โปรดเพิ่มระดับดาว",
  wl_withdraw_code_208: "ระดับดาวของผู้ใช้ปัจจุบันถึงจำนวนการถอนสูงสุดในวันนี้",

  wl_withdraw_order_01: "ทั้งหมด",
  wl_withdraw_order_02: "ไม่ได้รับการตรวจสอบ",
  wl_withdraw_order_03: "ตรวจสอบแล้ว",
  wl_withdraw_order_04: "การถอนเงินล้มเหลว",
  wl_withdraw_order_05: "หมายเลข",
  wl_withdraw_order_06: "ถอนเงิน",
  wl_withdraw_order_07: "วิธีการถอนเงิน",
  wl_withdraw_order_08: "ข้อมูลการถอนเงิน",
  wl_withdraw_order_09: "ต้องได้รับการตรวจสอบ",
  wl_withdraw_order_10: "อนุมัติ",
  wl_withdraw_order_11: "เสร็จสมบูรณ์",
  wl_withdraw_order_12: "ยกเลิก",
  wl_withdraw_order_13: "แช่แข็ง",
  wl_withdraw_order_14: "สาเหตุของการถอนล้มเหลว",

  hl_mine_invite_code: "รหัสเชิญของฉัน",
  hl_help_friend_invite_code: "ช่วยเหลือรหัสเชิญเพื่อน",
  hl_certification_activation_code: "รหัสคำเชิญ",
  hl_plz_write_invite_code: "กรุณากรอกรหัสเชิญ",
  hl_help_code_202: "รหัสเชิญความช่วยเหลือไม่ถูกต้อง",
  hl_help_code_203: "รหัสเชิญความช่วยเหลือไม่สามารถผูกกับตัวเองได้",
  hl_help_code_205:
    "ถึงจำนวนความช่วยเหลือสูงสุดแล้ว และไม่อนุญาตให้ช่วยเหลืออีกต่อไป",
  hl_help_code_206:
    "เพื่อนปัจจุบันได้ช่วยเหลือไปแล้วและไม่สามารถช่วยเหลือได้อีก",
  gl_visits: "การเข้าชม",

  _in_sp: "การจัดอันดับผลิตภัณฑ์",
  _r_k01: "การจัดอันดับ",
  _r_k02: "สินค้าโภคภัณฑ์",
  _r_k03: "ความร้อน",

  _zw_ph: "ยังไม่มีข้อมูลการจัดอันดับผลิตภัณฑ์! ",
  _zf_201: "สถานะการสั่งซื้อผิดปกติ",
  _zf_202: "ยอดสินทรัพย์ไม่เพียงพอ",
  _zf_203: "การชำระเงินล้มเหลว",
  _zf_204: "ธุรกรรมการค้าถูกระงับ โปรดติดต่อฝ่ายบริการลูกค้า",

  _withdraw_order_txt: "ถอนคำสั่ง",

  shop_num: "หมายเลขร้านค้า",
  plz_input_shop_num: "กรุณากรอกหมายเลขร้านค้า",
  shop_num_error: "หมายเลขร้านค้าไม่ถูกต้อง",
  supplier_num_error: "หมายเลขซัพพลายเออร์ไม่ถูกต้อง",
  supplier_num_has_bound: "หมายเลขซัพพลายเออร์ถูกผูกไว้",

  sc_day_sale: "ยอดขายรายวัน",
  sc_week_sale: "ยอดขายรายสัปดาห์",
  hl_mine_shop: "ร้านค้าของฉัน",
  hl_shop_name: "ชื่อร้านค้า",
  hl_input_shop_name: "กรุณากรอกชื่อร้านค้า",
  hl_add_shop_name: "เพิ่มชื่อร้านค้า",
  hl_update_shop_name: "แก้ไขชื่อร้านค้า",
  hl_update_suc: "การแก้ไขสำเร็จ",

  hl_rabate_01: "ค่าคอมมิชชั่นระดับล่าง",
  hl_rabate_02: "บันทึกส่วนลด",
  hl_rabate_03: "อัตราส่วนเงินคืน",
  hl_rabate_04: "จำนวนคอมมิชชันทั้งหมด",
  hl_rabate_05: "ยอดขายรวม",
  hl_rabate_06: "ยอดขาย",
  hl_rabate_07: "จำนวนเงินคืน",
  hl_rabate_08: "เวลาลงทะเบียน",
  hl_rabate_09: "ความช่วยเหลือระดับ 1",
  hl_rabate_10: "ความช่วยเหลือระดับ 2",
  hl_rabate_11: "ความช่วยเหลือระดับ 3",

  dd_order_01: "ยกเลิกคำสั่งซื้อ",
  dd_order_02: "ส่งคืน",
  dd_order_03: "กลับมา",
  dd_order_04: "ส่งคืนแล้ว",

  _t_p_qr: "ยืนยัน",
  _t_p_txt01:
    "จะยกเลิกคำสั่งซื้อหรือไม่ การยกเลิกคำสั่งซื้อไม่สามารถเพิกถอนได้และไม่พบ! ! ! ",
  _t_p_txt02: "โปรดยืนยันว่าคำสั่งซื้อนี้จำเป็นต้องส่งคืนหรือไม่! ",
  _t_p_txt03: "การยกเลิกสำเร็จ",
  _t_p_txt04: "ส่งคำขอคืนสินค้าแล้ว",

  showcase_01: "สั่งซื้อ",
  showcase_02: "ส่งคำสั่งซื้อเรียบร้อยแล้ว",
  showcase_03: "กรุณากรอก ID เพื่อนของคุณ",

  goods_price: "ราคา",

  u_pwd_tips: "โปรดติดต่อฝ่ายบริการลูกค้าเพื่อเปลี่ยนรหัสผ่านความปลอดภัย",

  g_to_be_purchased: "ที่จะซื้อ",
  g_purchasing_and_shipping: "การจัดซื้อและการจัดส่ง",
  g_suer_purchase_order: "ยืนยันการซื้อ",

  hl_star_rating_text_1: "ผู้ค้าหนึ่งดาว",
  hl_star_rating_text_2: "ผู้ค้าสองดาว",
  hl_star_rating_text_3: "พ่อค้าสามดาว",
  hl_star_rating_text_4: "พ่อค้าสี่ดาว",
  hl_star_rating_text_5: "ผู้ขายระดับห้าดาว",

  hl_auth_tips: "ได้ส่งการตรวจสอบสิทธิ์ชื่อจริงแล้วและอยู่ระหว่างการตรวจสอบ...",

  ml_01: "ยังไม่ได้อ่าน",
  ml_02: "อ่าน",
  ml_03: "เนื้อหาข้อความ...",
  ml_04: "ส่ง",
  ml_05: "กำลังอัพโหลด...",

  gl_sale_price: "ราคาขาย",

  wd_fee: "ค่าธรรมเนียมการจัดการ",
  wd_receipt: "มาถึงจริง",
  wd_receipt_way: "เลือกวิธีการชำระเงินที่มีอยู่",
  wd_bank_info: "ข้อมูลธนาคาร",
  wd_bank_info_tip: "รูปแบบ: ชื่อ-ชื่อธนาคาร-บัญชี ธนาคาร-หมายเลขบัตร",
  wd_success: "ถอนเงินสำเร็จ",
  wd_success_tip:
    "การสมัครถอนเงินของคุณสำเร็จแล้วและคาดว่าจะเข้าบัญชีของคุณภายใน 24 ชั่วโมง",

  g_removed: "ถูกลบออก",
  g_sale_ing: "ลดราคา",

  sd_01: "สินค้าแนะนำ",
  sd_02: "เพิ่มเติม",
  sd_03: "ร้านค้าแนะนำ",
  sd_04: "ผลิตภัณฑ์ปัจจุบัน",
  sd_05: "หนึ่ง",
  sd_06: "ดูร้านค้า",
  sd_07: "จัดเก็บผลิตภัณฑ์",
  sd_08: "ร้านค้ากิจกรรม",
  sd_09: "ร้านค้าผิดกฎหมาย",

  mc_01: "ลงนามแล้ว",
  mc_02: "ชำระเงินแล้ว",
  mc_03: "เวลาลงนาม",
  mc_04: "เวลาชำระเงิน",
  mc_05: "หมายเลขคำสั่งซื้อ",
  mc_06: "ลายเซ็น",
  mc_07: "ผู้ซื้อ",
  mc_08: "ที่อยู่",
  mc_09: "ภูมิภาค",
  mc_10: "ติดต่อผู้ซื้อ",

  lgi_01: "ข้อมูลโลจิสติกส์",
  lgi_02: "จับคู่คลังสินค้าระหว่างประเทศ",
  lgi_03: "กำลังดำเนินการจัดส่งด่วน",
  lgi_04: "อยู่ระหว่างการขนส่ง",
  lgi_05: "กำลังส่งมอบ",

  np_01: "คำเตือนความเสี่ยง",
  np_02: "ประกาศระบบ",
  np_03: "ฉันได้อ่านและเข้าใจแล้ว",
  np_04: "ข่าว",

  sl_01: "รายการในคลิกเดียว",

  w_pwd_info_01: "การถอนรหัสผ่าน",
  w_pwd_info_02: "ทำซ้ำรหัสผ่านการถอน",
  w_pwd_info_03: "โปรดป้อนรหัสผ่านการถอนอีกครั้ง",
  w_pwd_info_04: "รหัสผ่านการถอนทั้งสองไม่สอดคล้องกัน",
  w_pwd_info_05: "รหัสผ่านการถอนผิด",
  w_pwd_info_06:
    "หากคุณลืมรหัสผ่านการถอนเงิน โปรดติดต่อฝ่ายบริการลูกค้าเพื่อเปลี่ยนรหัสผ่านการถอนเงิน",

  l_g_01: "อีเมล",
  l_g_02: "กรุณาระบุที่อยู่อีเมลของคุณ",
  l_g_03: "รหัสยืนยัน",
  l_g_04: "กรุณากรอกรหัสยืนยัน",
  l_g_05: "ส่ง",
  l_g_06: "รหัสผ่านเข้าสู่ระบบ",
  l_g_07: "กรุณากรอกรหัสผ่านเข้าสู่ระบบของคุณ",
  l_g_08: "รหัสผ่านเข้าสู่ระบบซ้ำ",
  l_g_09: "กรุณากรอกรหัสผ่านเข้าสู่ระบบอีกครั้ง",
  l_g_10: "ลงทะเบียน",
  l_g_11: "เข้าสู่ระบบ",
  l_g_12: "มีบัญชีอยู่แล้ว เข้าสู่ระบบ",
  l_g_13: "ไปที่การลงทะเบียน",
  l_g_14: "ออกจากระบบ",
  l_g_15: "ยืนยันที่จะออกจากระบบ?",
  l_g_16: "ภาษา",
  l_g_17: "รหัสผ่านเข้าสู่ระบบทั้งสองไม่สอดคล้องกัน",
  l_g_18: "ไม่มีข้อมูลผู้ใช้ โปรดตรวจสอบว่าป้อน ID ถูกต้องหรือไม่~",
  l_g_19: "ชื่อผู้ใช้",
  l_g_20: "กรุณาระบุชื่อผู้ใช้",
  l_g_21: "เข้าสู่ระบบและคัดลอก",
  l_g_22: "ป้อนรหัสผ่านเข้าสู่ระบบไม่ถูกต้อง โปรดป้อนใหม่อีกครั้ง~",

  h_c_01: "สั่งซื้อกำไร",
  h_c_02: "ชำระเงินแล้ว",
  h_c_03: "กำลังจัดส่ง",
  h_c_04: "ยืนยันแล้ว",
  h_c_05: "การขาย",
  h_c_06: "รหัสเชิญเปิดร้าน",
  h_c_07: "กรุณากรอกรหัสเชิญร้านค้า",
  h_c_08: "เชิญเพื่อน",
  h_c_09: "รหัสเชิญร้านค้าผิด",
  h_c_10: "รหัสเชิญร้านค้าถูกใช้แล้ว",
  h_c_11: "มีชื่อผู้ใช้อยู่แล้ว",
  h_c_12: "ไม่มีข้อมูลผู้ใช้ โปรดตรวจสอบว่าข้อมูลที่ป้อนถูกต้องหรือไม่~",
  h_c_13: "บัญชีถูกระงับ",

  g_c_01: "คอลเลกชันของฉัน",
  g_c_02: "การรวบรวมสำเร็จ",

  h_g_01: "ราคาซื้อสินค้า",
  h_g_02: "จำนวนกำไร",
  h_g_03: "ราคาขายสินค้า",

  m_s_01: "รหัสยืนยัน",
  m_s_02: "กรุณากรอกรหัสยืนยัน",
  m_s_03: "รหัสยืนยันผิดพลาด โปรดลองอีกครั้ง~",
  m_s_04: "พ่อค้าชำระเงินแล้ว",
  m_s_05: "มีชื่อร้านค้าอยู่แล้ว~",

  __u_l_p: "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
  _u_l_p_01: "รหัสผ่านเก่า",
  _u_l_p_02: "โปรดป้อนรหัสผ่านเก่า",
  _u_l_p_03: "รหัสผ่านใหม่",
  _u_l_p_04: "กรุณากรอกรหัสผ่านใหม่",
  _u_l_p_05: "ทำซ้ำรหัสผ่านใหม่",
  _u_l_p_06: "โปรดป้อนรหัสผ่านใหม่อีกครั้ง",
  _u_l_p_07:
    "*หลังจากเปลี่ยนรหัสผ่านเข้าสู่ระบบแล้ว คุณต้องเข้าสู่ระบบอีกครั้ง",
  _u_l_p_08: "ป้อนรหัสผ่านเก่าไม่ถูกต้อง~",
};
