export default {
  shop_center_txt: "Einkaufszentrum",
  score_txt: "Punkte",
  minute_txt: "Minute",
  wallet_txt: "Wallet",
  today_txt: "Heute",
  week_txt: "diese Woche",
  month_txt: "diesen Monat",
  year_txt: "ganzes Jahr",
  main_indicators_txt: "Hauptindikatoren",
  sale_price: "Verkaufspreis",
  expected_profit: "erwarteter Gewinn",
  order_count: "Bestellmenge",
  goods_click_count: "Anzahl der Produktklicks",
  supplier_txt: "Lieferant",
  friends_help_txt: "Freunde helfen",
  feedback_txt: "Feedback",
  feedback: "Rückmeldung",
  online_service_txt_1: "Kundenservice einzahlen",
  online_service_txt_2: "Unternehmensberatung",
  q_and_a_txt: "Frage und Antwort",
  open_now_txt: "Öffnen",
  time_txt: "Zeit",
  times_txt: "Zeit",

  wallet_total_assets: "Gesamtvermögen",
  balance_txt: "Balance",
  pending_settlement_txt: "Ausstehende Abrechnung",
  recharge_txt: "Aufladen",
  withdraw_txt: "Zurückziehen",
  fund_details_txt: "Fondsdetails",
  no_more_txt: "Nicht mehr",
  no_data_txt: "Noch keine Daten",
  detail_txt: "Details",
  num_txt: "Nummer",
  money_txt: "Betrag",
  remark_txt: "Bemerkungen",
  no_remark_txt: "Kein Kommentar",
  tip_txt: "tip",
  tip_content_txt:
    "Aufgrund von Währungsproblemen, die derzeit von Händlern in verschiedenen Ländern übernommen werden",
  need_contact_service: "Sie müssen den Kundendienst kontaktieren",
  contact_service: "Kundendienst kontaktieren",

  feedback_title: "Feedback Ihrer Meinung",
  feedback_placeholder:
    "Bitte geben Sie aus Sicherheitsgründen keine persönlichen Daten an",
  submit_txt: "Senden",
  sure_txt: "OK",
  submit_suc_txt: "Übermittlung erfolgreich",

  loading: "Laden...",

  newer_action: "Neulingsaktivität",
  newer_traffic_package: "Newcomer-Support-Traffic-Paket",
  newer_get_millions_of_products: "Millionen Produkte erhalten",
  newer_get: "Received",
  newer_receive: "empfangen",
  newer_action_ruler: "Aktivitätsregeln für Neulinge",
  newer_receive_suc: "Erfolgreich empfangen",

  help_friends_help: "Freunde helfen",
  help_your_friends_stores: "Hilf deinen Freunden",
  get_store_product_heating: "Ladenproduktheizung abrufen",
  help_friends_txt: "Freunden helfen",
  help_friends_activity_rules: "Aktivitätsregeln",
  assistance_details: "Hilfedetails",
  assistance_details_other: "Einzelheiten zur Unterstützung",
  team_list: "Teamliste",
  help_friend_name: "Name des Freundes helfen",
  help_friend_invite_code: "Einladungscode für Freunde helfen",
  mine_invite_code: "Mein Einladungscode",
  plz_input: "Bitte eingeben",
  plz_input_friend_name:
    "Bitte geben Sie den Namen Ihres Freundes ein, um zu helfen",
  help_suc: "Hilfe zum Erfolg",

  online_service: "Online-Kundenservice",
  online_24_hour_service: "24-Stunden-Online-Support",
  customer_service: "Kundenservice",
  copy_and_add_id: "Zeilen-ID kopieren und hinzufügen",
  plz_input_question_detail: "Bitte geben Sie die Details Ihrer Frage ein",
  harry_reply_your: "Wir werden Ihnen so schnell wie möglich antworten",
  click_copy: "Zum Kopieren klicken",
  copy_suc: "Kopieren erfolgreich",
  copy_fail: "Kopieren fehlgeschlagen",

  overall: "alle",
  cooperate: "kooperieren",
  cancel: "abbrechen",
  supplier_num: "Lieferantennummer",
  no_use_supplier: "Kein Lieferant verfügbar",
  here_add_supplier: "Hier neuen Lieferanten hinzufügen",
  add_supplier: "Lieferant hinzufügen",
  plz_input_supplier_num: "Bitte geben Sie die Lieferantennummer ein",
  add_suc: "Erfolgreich hinzufügen",
  remove_suc: "Erfolgreich entfernt",

  new_products_launched: "Neue Produkte eingeführt",
  profit_txt: "Gewinn",
  has_been_added: "Bereits in den Regalen",
  on_display: "Kann in Regale gestellt werden",
  coming_soon_txt: "Jetzt verfügbar",
  sold_txt: "Verkauft",
  removed_txt: "Entfernt",

  order_detail_txt: "Bestelldetails",
  waiting_express_delivery: "Warten auf Expresslieferung",
  customer_info: "Kundeninformationen",
  order_good: "Waren bestellen",
  order_num: "Bestellnummer",
  order_date: "Bestelldatum",
  order_content: "Bestellzusammenfassung",
  purchase_price_txt: "Kaufpreis",
  income_txt: "Einkommen",

  order_txt: "Bestellung",
  pending_zahlung: "Zahlung ausstehend",
  ready_for_shipment: "Zu versenden",
  shipped_txt: "versendet",
  paid_txt: "Geliefert",
  good_num: "Artikelnummer",
  pay_and_ship: "Zahlung und Versand",
  pay_and_ship_all: "Alles bezahlen",
  pay_suc: "Zahlung erfolgreich",
  no_order: "Noch keine Bestellung",
  no_order_tips: "Hier können Sie Ihre Bestellungen und Retouren verfolgen",
  suer_order: "Zahlung bestätigen",
  pay_way: "Zahlungsmethode",
  wallet_pay: "Wallet-Zahlung",
  sure_pay: "Zahlung bestätigen",
  paying_txt: "Bezahlt...",

  manage_showcase: "Showcase verwalten",
  add_new_good: "Neues Produkt hinzufügen",
  heating_txt: "Heizung",
  promotion: "Beförderung",
  promotion_tip: "Werbetipp",
  promotion_tip_txt:
    "Ihr aktueller Punktestand liegt unter 200 und Sie können Ihre Produkte nicht bezahlen",
  copy_link: "Link kopieren",
  scan_qr_code: "QR-Code scannen",

  about_good: "Über das Produkt",
  specification: "Spezifikation",
  product_overview: "Produktübersicht",

  top_tips_1: "Online-Überweisung im Tiktok",
  top_tips_2: "Unterstützt derzeit nur interne Einladungen",

  certification_activation_code: "Einladungscode",
  plz_write_invite_code: "Bitte geben Sie den Einladungscode ein",
  active_user_email: "E-Mail",
  plz_input_active_user_email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
  active_user_email_code: "E-Mail-Bestätigungscode",
  plz_input_active_user_email_code:
    "Bitte geben Sie den E-Mail-Bestätigungscode ein",
  active_get_email_code_txt: "Bestätigungscode abrufen",
  active_user_name: "Name",
  plz_input_active_user_name: "Bitte geben Sie Ihren Namen ein",
  active_user_id: "Personalausweis-/Reisepassnummer",
  plz_input_active_user_id:
    "Bitte geben Sie Ihre Personalausweis-/Reisepassnummer ein",
  upload_active_user_id: "Zertifikat-Upload",
  plz_upload_user_id_img:
    "Bitte laden Sie das Foto der Vorderseite Ihres Personalausweises/Reisepasses hoch",

  promotion_01: "Promotion",
  promotion_02: "Aktionsbetrag",
  promotion_03: "Bitte geben Sie den Aktionsbetrag ein",
  promotion_04: "Promotion bestätigen",
  promotion_05: "Promotion erfolgreich",

  bao_txt: "Belichtungsrate",
  bal_no: "Unzureichendes Guthaben",

  wallet_type_01: "Aufladeanwendung",
  wallet_type_02: "Auszahlungsantrag",
  wallet_type_03: "Zahlung",
  wallet_type_04: "Systemabzug",
  wallet_type_05: "Gewinneinkommen",
  wallet_type_06: "Zahlungsabzug",
  wallet_type_07: "Verteilungsverbrauch",
  wallet_type_08: "Werbeeinnahmen",
  wallet_type_09: "Steigern Sie die Belohnungen",
  wallet_type_10: "Kostenlos registrieren",
  wallet_type_11: "Umsatzprämie",
  wallet_type_12: "Auszahlung abgelehnt",

  all_orders: "Alle Bestellungen",
  mine_orders: "Meine Bestellungen",
  mine_services: "Meine Dienste",
  _tzz: "Springen...",

  info_01: "Name",
  info_02: "E-Mail",
  info_03: "Kontaktinformationen",
  info_04: "Geschlecht",
  info_05: "Vertraulich",
  info_06: "Männlich",
  info_07: "Weiblich",
  info_08: "Geburtsdatum(yyyy-MM-dd)",
  info_09: "Land",
  info_10: "Stadt",
  info_11: "Kartennummer",
  info_12: "Kartentyp",
  info_13: "Kartenherausgeber",
  info_14: "Ablaufdatum(yyyy-MM-dd)",
  info_15: "CVV-Code",
  info_16: "Sicherheitspasswort",
  info_17: "Sicherheitspasswort wiederholen",
  info_18: "Bitte geben Sie das Sicherheitspasswort erneut ein",
  info_19: "Die beiden Sicherheitspasswörter sind inkonsistent",
  info_20: "Personalausweis/Reisepass",
  info_21: "Wählen Sie den Bildtyp zum Hochladen aus",
  info_22: "Ausweis (Vorder- und Rückseite)",
  info_23: "Reisepass",
  info_24: "Führerschein",
  info_25: "Aufenthaltserlaubnis",
  info_26: "Gesundheitszeugnis",
  info_27: "Bitte Ausweisfoto hochladen",
  info_28: "Eingereicht, bitte warten Sie geduldig",
  info_29: "Kontonummer",
  info_30: "Bankname",

  wl_withdraw_money: "Auszahlungsbetrag",
  wl_withdraw_code_201:
    "Auszahlungsdatensätze werden derzeit überprüft, bitte nicht erneut einreichen",
  wl_withdraw_code_202: "Die Bankkarteninformationen sind falsch",
  wl_withdraw_code_400: "Ausnahme bei Auszahlungsdatensatz",
  wl_withdraw_code_204: "Falsches Sicherheitspasswort",
  wl_withdraw_code_205: "Der Margin-Saldo kann nicht abgehoben werden",
  wl_withdraw_code_207:
    "Die Sternebewertung des aktuellen Benutzers kann nicht zurückgezogen werden, bitte erhöhen Sie die Sternebewertung",
  wl_withdraw_code_208:
    "Die Sternebewertung des aktuellen Benutzers hat heute die maximale Anzahl an Auszahlungen erreicht",

  wl_withdraw_order_01: "Alle",
  wl_withdraw_order_02: "Nicht überprüft",
  wl_withdraw_order_03: "Geprüft",
  wl_withdraw_order_04: "Die Auszahlung ist fehlgeschlagen",
  wl_withdraw_order_05: "Nummer",
  wl_withdraw_order_06: "Betrag abheben",
  wl_withdraw_order_07: "Auszahlungsmethode",
  wl_withdraw_order_08: "Auszahlungsinformationen",
  wl_withdraw_order_09: "Zur Überprüfung",
  wl_withdraw_order_10: "Genehmigt",
  wl_withdraw_order_11: "Abgeschlossen",
  wl_withdraw_order_12: "Storniert",
  wl_withdraw_order_13: "Eingefroren",
  wl_withdraw_order_14: "Grund für das Scheitern der Auszahlung",

  hl_mine_invite_code: "Mein Einladungscode",
  hl_help_friend_invite_code: "Einladungscode für Freunde helfen",
  hl_certification_activation_code: "Einladungscode",
  hl_plz_write_invite_code: "Bitte geben Sie den Einladungscode ein",
  hl_help_code_202: "Falscher Hilfeeinladungscode",
  hl_help_code_203:
    "Der Hilfeeinladungscode kann nicht an sich selbst gebunden werden",
  hl_help_code_205:
    "Die maximale Anzahl an Hilfen wurde erreicht und es ist keine weitere Hilfe erlaubt",
  hl_help_code_206:
    "Der aktuelle Freund hat bereits geholfen und kann nicht noch einmal helfen",
  gl_visits: "Besuche",

  _in_sp: "Produktranking",
  _r_k01: "Rangliste",
  _r_k02: "Ware",
  _r_k03: "Hitze",

  _zw_ph: "Noch keine Informationen zum Produktranking! ",
  _zf_201: "Bestellstatus abnormal",
  _zf_202: "Unzureichender Vermögensbestand",
  _zf_203: "Zahlung fehlgeschlagen",
  _zf_204:
    "Händlertransaktionen wurden eingefroren, bitte wenden Sie sich an den Kundendienst",

  _withdraw_order_txt: "Bestellung zurückziehen",

  shop_num: "Shop Nummer",
  plz_input_shop_num: "Bitte geben Sie die Shop Nummer ein",
  shop_num_error: "Die Filialnummer ist falsch",
  supplier_num_error: "Die Lieferantennummer ist falsch",
  supplier_num_has_bound: "Die Lieferantennummer wurde gebunden",

  sc_day_sale: "Tägliche Verkäufe",
  sc_week_sale: "wöchentliche Verkäufe",
  hl_mine_shop: "Mein Shop",
  hl_shop_name: "Shopname",
  hl_input_shop_name: "Bitte geben Sie den Shopnamen ein",
  hl_add_shop_name: "Shopnamen hinzufügen",
  hl_update_shop_name: "Shop-Namen ändern",
  hl_update_suc: "Änderung erfolgreich",

  hl_rabate_01: "Provision auf niedrigerer Ebene",
  hl_rabate_02: "Rabattdatensatz",
  hl_rabate_03: "Rabattverhältnis",
  hl_rabate_04: "Gesamtprovisionsbetrag",
  hl_rabate_05: "Gesamtumsatz",
  hl_rabate_06: "Verkaufsbetrag",
  hl_rabate_07: "Rabattbetrag",
  hl_rabate_08: "Registrierungszeit",
  hl_rabate_09: "Unterstützung der Stufe 1",
  hl_rabate_10: "Unterstützung der Stufe 2",
  hl_rabate_11: "Unterstützung der Stufe 3",

  dd_order_01: "Bestellung stornieren",
  dd_order_02: "Return",
  dd_order_03: "Rückkehr",
  dd_order_04: "Zurückgegeben",

  _t_p_qr: "Bestätigen",
  _t_p_txt01:
    "Ob die Bestellung storniert werden soll? Die Stornierung der Bestellung ist unwiderruflich und kann nicht gefunden werden! ! ! ",
  _t_p_txt02:
    "Bitte bestätigen Sie, ob diese Bestellung zurückgegeben werden muss! ",
  _t_p_txt03: "Stornierung erfolgreich",
  _t_p_txt04: "Rückgabeantrag wurde eingereicht",

  showcase_01: "Bestellung aufgeben",
  showcase_02: "Bestellung erfolgreich aufgegeben",
  showcase_03: "Bitte geben Sie Ihre Freundes-ID ein",

  goods_price: "Preis",

  u_pwd_tips:
    "Bitte wenden Sie sich an den Kundendienst, um das Sicherheitspasswort zu ändern",

  g_to_be_purchased: "zu kaufen",
  g_purchasing_and_shipping: "Einkauf und Versand",
  g_suer_purchase_order: "Kauf bestätigen",

  hl_star_rating_text_1: "Ein Stern Händler",
  hl_star_rating_text_2: "Zwei Sterne Händler",
  hl_star_rating_text_3: "Drei Sterne Händler",
  hl_star_rating_text_4: "Vier Sterne Händler",
  hl_star_rating_text_5: "Fünf Sterne Händler",

  hl_auth_tips:
    "Authentifizierung mit echtem Namen wurde übermittelt und wird derzeit überprüft...",

  ml_01: "Ungelesen",
  ml_02: "Lesen",
  ml_03: "Nachrichteninhalt…",
  ml_04: "Senden",
  ml_05: "Hochladen…",

  gl_sale_price: "Verkaufspreis",

  wd_fee: "Bearbeitungsgebühr",
  wd_receipt: "Tatsächlicher Eingang",
  wd_receipt_way: "Wählen Sie eine vorhandene Zahlungsmethode aus",
  wd_bank_info: "Bankinformationen",
  wd_bank_info_tip: "Format: Name-Bankname-Konto-Bankkartennummer",
  wd_success: "Erfolgreich abheben",
  wd_success_tip:
    "Ihr Auszahlungsantrag war erfolgreich und wird voraussichtlich innerhalb von 24 Stunden auf Ihrem Konto eingehen",

  g_removed: "Entfernt",
  g_sale_ing: "Im Angebot",

  sd_01: "Empfohlene Produkte",
  sd_02: "Mehr",
  sd_03: "Empfohlener Shop",
  sd_04: "Aktuelles Produkt",
  sd_05: "Eins",
  sd_06: "Shop ansehen",
  sd_07: "Produkte speichern",
  sd_08: "Aktivitätsspeicher",
  sd_09: "Illegaler Shop",

  mc_01: "Unterschrieben für",
  mc_02: "Bezahlt",
  mc_03: "Signaturzeit",
  mc_04: "Zahlungszeitpunkt",
  mc_05: "Bestellnummer",
  mc_06: "Signatur",
  mc_07: "Käufer",
  mc_08: "Adresse",
  mc_09: "Region",
  mc_10: "Käufer kontaktieren",

  lgi_01: "Logistikinformationen",
  lgi_02: "Internationales Warenlager abgleichen",
  lgi_03: "Expresszustellung läuft",
  lgi_04: "Unterwegs",
  lgi_05: "Lieferung",

  np_01: "Risikowarnung",
  np_02: "Systemankündigung",
  np_03: "Ich habe gelesen und verstanden",
  np_04: "Neuigkeiten",

  sl_01: "Ein-Klick-Eintrag",

  w_pwd_info_01: "Entzugspasswort",
  w_pwd_info_02: "Auszahlungspasswort wiederholen",
  w_pwd_info_03: "Bitte geben Sie das Auszahlungspasswort erneut ein",
  w_pwd_info_04: "Die beiden Auszahlungspasswörter sind inkonsistent",
  w_pwd_info_05: "Falsches Auszahlungspasswort",
  w_pwd_info_06:
    "Wenn Sie Ihr Auszahlungspasswort vergessen haben, wenden Sie sich bitte an den Kundendienst, um das Auszahlungspasswort zu ändern",

  l_g_01: "E-Mail",
  l_g_02: "Bitte geben Sie Ihre E-Mail-Adresse ein",
  l_g_03: "Bestätigungscode",
  l_g_04: "Bitte geben Sie den Bestätigungscode ein",
  l_g_05: "Senden",
  l_g_06: "Login-Passwort",
  l_g_07: "Bitte geben Sie Ihr Login-Passwort ein",
  l_g_08: "Login-Passwort wiederholen",
  l_g_09: "Bitte geben Sie Ihr Login-Passwort erneut ein",
  l_g_10: "Registrieren",
  l_g_11: "Anmelden",
  l_g_12: "Haben Sie bereits ein Konto, melden Sie sich an",
  l_g_13: "Zur Registrierung gehen",
  l_g_14: "Abmelden",
  l_g_15: "Zum Abmelden bestätigen?",
  l_g_16: "Sprache",
  l_g_17: "Die beiden Login-Passwörter sind inkonsistent",
  l_g_18:
    "Benutzerinformationen sind nicht vorhanden, bitte überprüfen Sie, ob die ID korrekt eingegeben wurde~",
  l_g_19: "Benutzername",
  l_g_20: "Bitte Benutzernamen eingeben",
  l_g_21: "Anmelden und kopieren",
  l_g_22:
    "Das Login-Passwort wurde falsch eingegeben, bitte geben Sie es erneut ein~",

  h_c_01: "Bestellgewinn",
  h_c_02: "Bezahlt",
  h_c_03: "Wird versendet",
  h_c_04: "Bestätigt",
  h_c_05: "Verkäufe",
  h_c_06: "Einladungscode zur Ladeneröffnung",
  h_c_07: "Bitte geben Sie den Store-Einladungscode ein",
  h_c_08: "Freunde einladen",
  h_c_09: "Falscher Store-Einladungscode",
  h_c_10: "Der Store-Einladungscode wurde verwendet",
  h_c_11: "Benutzername existiert bereits",
  h_c_12:
    "Benutzerinformationen sind nicht vorhanden, bitte überprüfen Sie, ob die Informationen korrekt eingegeben wurden~",
  h_c_13: "Konto wurde eingefroren",

  g_c_01: "Meine Sammlung",
  g_c_02: "Erfassung erfolgreich",

  h_g_01: "Produktkaufpreis",
  h_g_02: "Gewinnbetrag",
  h_g_03: "Produktverkaufspreis",

  m_s_01: "Bestätigungscode",
  m_s_02: "Bitte geben Sie den Bestätigungscode ein",
  m_s_03: "Bestätigungscode-Fehler, bitte versuchen Sie es erneut~",
  m_s_04: "Händler hat sich eingelebt",
  m_s_05: "Der Shopname existiert bereits~",

  _u_l_p: "Login-Passwort ändern",
  _u_l_p_01: "Altes Passwort",
  _u_l_p_02: "Bitte geben Sie das alte Passwort ein",
  _u_l_p_03: "Neues Passwort",
  _u_l_p_04: "Bitte geben Sie ein neues Passwort ein",
  _u_l_p_05: "Neues Passwort wiederholen",
  _u_l_p_06: "Bitte geben Sie das neue Passwort erneut ein",
  _u_l_p_07:
    "*Nachdem Sie das Login-Passwort geändert haben, müssen Sie sich erneut anmelden.",
  _u_l_p_08: "Das alte Passwort wurde falsch eingegeben~",
};
