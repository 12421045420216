export default {
  shop_center_txt: "Pusat toko",
  score_txt: "Poin",
  minute_txt: "menit",
  wallet_txt: "dompet",
  today_txt: "Hari ini",
  week_txt: "minggu ini",
  month_txt: "bulan ini",
  year_txt: "setahun penuh",
  main_indicators_txt: "Indikator utama",
  sale_price: "harga jual",
  expected_profit: "keuntungan yang diharapkan",
  order_count: "Jumlah pesanan",
  goods_click_count: "Jumlah klik produk",
  supplier_txt: "Pemasok",
  friends_help_txt: "Bantuan teman",
  feedback_txt: "Masukan",
  feedback: "Umpan Balik",
  online_service_txt_1: "Layanan pelanggan setor",
  online_service_txt_2: "Konsultasi bisnis",
  q_and_a_txt: "Tanya Jawab",
  open_now_txt: "Buka",
  time_txt: "waktu",
  times_txt: "waktu",

  wallet_total_assets: "Total aset",
  balance_txt: "Saldo",
  pending_settlement_txt: "penyelesaian tertunda",
  recharge_txt: "Isi ulang",
  withdraw_txt: "Penarikan",
  fund_details_txt: "Detail dana",
  no_more_txt: "Tidak ada lagi",
  no_data_txt: "Belum ada data",
  detail_txt: "Detail",
  num_txt: "Nomor",
  money_txt: "jumlah",
  remark_txt: "Keterangan",
  no_remark_txt: "Tidak ada komentar",
  tip_txt: "tip",
  tip_content_txt:
    "Saat ini karena masalah mata uang yang ditanggung oleh pedagang di berbagai negara",
  need_contact_service: "Perlu menghubungi layanan pelanggan",
  contact_service: "Hubungi layanan pelanggan",

  feedback_title: "Masukan pendapat Anda",
  feedback_placeholder:
    "Untuk alasan keamanan, mohon jangan sertakan informasi pribadi",
  submit_txt: "Kirim",
  sure_txt: "Oke",
  submit_suc_txt: "Pengiriman berhasil",

  loading: "Memuat...",

  newer_action: "Aktivitas pendatang baru",
  newer_traffic_package: "Paket lalu lintas dukungan pendatang baru",
  newer_get_millions_of_products: "Dapatkan jutaan produk",
  newer_get: "Diterima",
  newer_receive: "menerima",
  newer_action_ruler: "Aturan aktivitas pendatang baru",
  newer_receive_suc: "Berhasil diterima",

  help_friends_help: "Bantuan teman",
  help_your_friends_stores: "Bantu temanmu",
  get_store_product_heating: "Dapatkan pemanas produk toko",
  help_friends_txt: "Bantu teman",
  help_friends_activity_rules: "Aturan aktivitas",
  assistance_details: "Detail bantuan",
  assistance_details_other: "Detail bantuan",
  team_list: "Daftar tim",
  help_friend_name: "Bantuan nama teman",
  help_friend_invite_code: "Bantuan kode undangan teman",
  mine_invite_code: "Kode undangan saya",
  plz_input: "Silakan masuk",
  plz_input_friend_name: "Silakan masukkan nama teman Anda untuk membantu",
  help_suc: "Bantu kesuksesan",

  online_service: "Layanan pelanggan online",
  online_24_hour_service: "dukungan online 24 jam",
  customer_service: "layanan pelanggan",
  copy_and_add_id: "Salin dan tambahkan ID LINE",
  plz_input_question_detail: "Silahkan masukkan rincian pertanyaan Anda",
  harry_reply_your: "Kami akan membalas Anda sesegera mungkin",
  click_copy: "Klik untuk menyalin",
  copy_suc: "Penyalinan berhasil",
  copy_fail: "Penyalinan gagal",

  overall: "semua",
  cooperate: "bekerja sama",
  cancel: "batal",
  supplier_num: "Nomor pemasok",
  no_use_supplier: "Tidak ada pemasok yang tersedia",
  here_add_supplier: "Tambahkan pemasok baru di sini",
  add_supplier: "Tambahkan pemasok",
  plz_input_supplier_num: "Silakan masukkan nomor pemasok",
  add_suc: "Berhasil menambahkan",
  remove_suc: "Berhasil dihapus",

  new_products_launched: "Produk baru diluncurkan",
  profit_txt: "Keuntungan",
  has_been_added: "Sudah ada di rak",
  on_display: "Bisa ditaruh di rak",
  coming_soon_txt: "Tersedia sekarang",
  sold_txt: "Terjual",
  removed_txt: "Dihapus",

  order_detail_txt: "Detail pesanan",
  waiting_express_delivery: "Menunggu pengiriman ekspres",
  customer_info: "Informasi pelanggan",
  order_good: "Pesan Barang",
  order_num: "Nomor pesanan",
  order_date: "tanggal pemesanan",
  order_content: "Ringkasan Pesanan",
  purchase_price_txt: "Harga pembelian",
  income_txt: "Pendapatan",

  order_txt: "Pesan",
  pending_payment: "pembayaran tertunda",
  ready_for_shipment: "Akan dikirim",
  shipped_txt: "dikirim",
  paid_txt: "Terkirim",
  good_num: "nomor barang",
  pay_and_ship: "Pembayaran dan Pengiriman",
  pay_and_ship_all: "Bayar semua",
  pay_suc: "Pembayaran berhasil",
  no_order: "Belum ada pesanan",
  no_order_tips: "Anda dapat melacak pesanan dan pengembalian Anda di sini",
  suer_order: "Konfirmasi pembayaran",
  pay_way: "metode pembayaran",
  wallet_pay: "pembayaran dompet",
  sure_pay: "Konfirmasi pembayaran",
  paying_txt: "Membayar...",

  manage_showcase: "Kelola etalase",
  add_new_good: "Tambahkan produk baru",
  heating_txt: "Pemanasan",
  promotion: "promosi",
  promotion_tip: "Tips promosi",
  promotion_tip_txt:
    "Poin Anda saat ini kurang dari 200 dan Anda tidak dapat membayar produk Anda",
  copy_link: "Salin tautan",
  scan_qr_code: "Pindai kode QR",

  about_good: "Tentang produk",
  specification: "spesifikasi",
  product_overview: "Ikhtisar Produk",

  top_tips_1: "Transformasi toko Douyin online",
  top_tips_2: "Saat ini hanya mendukung undangan internal",

  certification_activation_code: "kode undangan",
  plz_write_invite_code: "Silakan masukkan kode undangan",
  active_user_email: "email",
  plz_input_active_user_email: "Silakan masukkan email Anda",
  active_user_email_code: "Kode verifikasi email",
  plz_input_active_user_email_code: "Silakan masukkan kode verifikasi email",
  active_get_email_code_txt: "Dapatkan kode verifikasi",
  active_user_name: "nama",
  plz_input_active_user_name: "Silakan masukkan nama Anda",
  active_user_id: "Nomor KTP/paspor",
  plz_input_active_user_id: "Silakan masukkan nomor KTP/paspor Anda",
  upload_active_user_id: "Unggahan sertifikat",
  plz_upload_user_id_img: "Silakan upload foto halaman depan KTP/paspor Anda",

  promotion_01: "Promosi",
  promotion_02: "Jumlah promosi",
  promotion_03: "Silakan masukkan jumlah promosi",
  promotion_04: "Konfirmasi promosi",
  promotion_05: "Promosi berhasil",

  bao_txt: "Tingkat paparan",
  bal_no: "Saldo tidak mencukupi",

  wallet_type_01: "Aplikasi Isi Ulang",
  wallet_type_02: "Aplikasi penarikan",
  wallet_type_03: "Pembayaran",
  wallet_type_04: "Pengurangan sistem",
  wallet_type_05: "Pendapatan keuntungan",
  wallet_type_06: "Potongan pembayaran",
  wallet_type_07: "Konsumsi distribusi",
  wallet_type_08: "Pendapatan promosi",
  wallet_type_09: "Tingkatkan imbalan",
  wallet_type_10: "Daftar gratis",
  wallet_type_11: "Hadiah Omset",
  wallet_type_12: "Penarikan ditolak",

  all_orders: "Semua pesanan",
  mine_orders: "Pesanan saya",
  mine_services: "Layanan saya",
  _tzz: "Melompat...",

  info_01: "Nama",
  info_02: "Email",
  info_03: "Informasi kontak",
  info_04: "Jenis Kelamin",
  info_05: "Rahasia",
  info_06: "Laki-laki",
  info_07: "Wanita",
  info_08: "Tanggal lahir(yyyy-MM-dd)",
  info_09: "Negara",
  info_10: "Kota",
  info_11: "Nomor kartu",
  info_12: "Jenis kartu",
  info_13: "Penerbit Kartu",
  info_14: "Tanggal kedaluwarsa(yyyy-MM-dd)",
  info_15: "Kode CVV",
  info_16: "Kata sandi keamanan",
  info_17: "Ulangi kata sandi keamanan",
  info_18: "Silakan masukkan kembali kata sandi keamanan",
  info_19: "Kedua kata sandi keamanan tidak konsisten",
  info_20: "KTP/paspor",
  info_21: "Pilih jenis gambar yang akan diunggah",
  info_22: "KTP (depan dan belakang)",
  info_23: "Paspor",
  info_24: "SIM",
  info_25: "Izin tinggal",
  info_26: "Surat Keterangan Kesehatan",
  info_27: "Silakan upload foto identitas",
  info_28: "Dikirim, harap sabar menunggu",
  info_29: "Nomor rekening",
  info_30: "Nama Bank",

  wl_withdraw_money: "Jumlah penarikan",
  wl_withdraw_code_201:
    "Catatan penarikan sedang ditinjau, mohon jangan dikirim lagi",
  wl_withdraw_code_202: "Informasi kartu bank salah",
  wl_withdraw_code_400: "Pengecualian catatan penarikan",
  wl_withdraw_code_204: "Kata sandi keamanan salah",
  wl_withdraw_code_205: "Saldo margin tidak dapat ditarik",
  wl_withdraw_code_207:
    "Peringkat bintang pengguna saat ini tidak dapat ditarik, harap tingkatkan peringkat bintangnya",
  wl_withdraw_code_208:
    "Peringkat bintang pengguna saat ini telah mencapai jumlah maksimum penarikan hari ini",

  wl_withdraw_order_01: "Semua",
  wl_withdraw_order_02: "Belum ditinjau",
  wl_withdraw_order_03: "Diaudit",
  wl_withdraw_order_04: "Penarikan gagal",
  wl_withdraw_order_05: "Nomor",
  wl_withdraw_order_06: "Jumlah penarikan",
  wl_withdraw_order_07: "Metode penarikan",
  wl_withdraw_order_08: "Informasi penarikan",
  wl_withdraw_order_09: "Untuk ditinjau",
  wl_withdraw_order_10: "Disetujui",
  wl_withdraw_order_11: "Selesai",
  wl_withdraw_order_12: "Dibatalkan",
  wl_withdraw_order_13: "beku",
  wl_withdraw_order_14: "Alasan kegagalan penarikan",

  hl_mine_invite_code: "Kode undangan saya",
  hl_help_friend_invite_code: "Bantuan kode undangan teman",
  hl_certification_activation_code: "kode undangan",
  hl_plz_write_invite_code: "Silakan masukkan kode undangan",
  hl_help_code_202: "Kode undangan bantuan salah",
  hl_help_code_203:
    "Kode undangan bantuan tidak dapat terikat pada dirinya sendiri",
  hl_help_code_205:
    "Jumlah bantuan maksimum telah tercapai dan tidak ada bantuan lagi yang diperbolehkan",
  hl_help_code_206:
    "Teman saat ini sudah membantu dan tidak dapat membantu lagi",
  gl_visits: "Kunjungan",

  _in_sp: "Peringkat produk",
  _r_k01: "Peringkat",
  _r_k02: "Komoditas",
  _r_k03: "panas",

  _zw_ph: "Belum ada informasi peringkat produk! ",
  _zf_201: "Status pesanan tidak normal",
  _zf_202: "Saldo aset tidak mencukupi",
  _zf_203: "Pembayaran gagal",
  _zf_204:
    "Transaksi pedagang telah dibekukan, silakan hubungi layanan pelanggan",

  _withdraw_order_txt: "Batalkan pesanan",

  shop_num: "Nomor toko",
  plz_input_shop_num: "Silakan masukkan nomor toko",
  shop_num_error: "Nomor toko salah",
  supplier_num_error: "Nomor pemasok salah",
  supplier_num_has_bound: "Nomor pemasok telah terikat",

  sc_day_sale: "Penjualan harian",
  sc_week_sale: "Penjualan mingguan",
  hl_mine_shop: "Toko Saya",
  hl_shop_name: "Nama toko",
  hl_input_shop_name: "Silakan masukkan nama toko",
  hl_add_shop_name: "Tambahkan nama toko",
  hl_update_shop_name: "Ubah nama toko",
  hl_update_suc: "Modifikasi berhasil",

  hl_rabate_01: "Komisi tingkat rendah",
  hl_rabate_02: "Catatan rabat",
  hl_rabate_03: "Rasio rabat",
  hl_rabate_04: "Jumlah total komisi",
  hl_rabate_05: "Total penjualan",
  hl_rabate_06: "Jumlah penjualan",
  hl_rabate_07: "Jumlah rabat",
  hl_rabate_08: "Waktu pendaftaran",
  hl_rabate_09: "Bantuan tingkat 1",
  hl_rabate_10: "Bantuan tingkat 2",
  hl_rabate_11: "Bantuan tingkat 3",

  dd_order_01: "Batalkan pesanan",
  dd_order_02: "Kembali",
  dd_order_03: "Kembali",
  dd_order_04: "Dikembalikan",

  _t_p_qr: "Konfirmasi",
  _t_p_txt01:
    "Apakah akan membatalkan pesanan? Pembatalan pesanan tidak dapat dibatalkan dan tidak dapat ditemukan! ! ! ",
  _t_p_txt02: "Mohon konfirmasi apakah pesanan ini perlu dikembalikan! ",
  _t_p_txt03: "Pembatalan berhasil",
  _t_p_txt04: "Permintaan pengembalian telah dikirimkan",

  showcase_01: "Pesan",
  showcase_02: "Pesanan berhasil dilakukan",
  showcase_03: "Silakan masukkan ID teman Anda",

  goods_price: "Harga",

  u_pwd_tips:
    "Silakan hubungi layanan pelanggan untuk mengubah kata sandi keamanan",

  g_to_be_purchased: "untuk dibeli",
  g_purchasing_and_shipping: "Pembelian dan Pengiriman",
  g_suer_purchase_order: "Konfirmasi pembelian",

  hl_star_rating_text_1: "Pedagang bintang satu",
  hl_star_rating_text_2: "Pedagang bintang dua",
  hl_star_rating_text_3: "Pedagang bintang tiga",
  hl_star_rating_text_4: "Pedagang bintang empat",
  hl_star_rating_text_5: "Pedagang bintang lima",

  hl_auth_tips: "Otentikasi nama asli telah dikirimkan dan sedang ditinjau...",

  ml_01: "Belum dibaca",
  ml_02: "Baca",
  ml_03: "Isi pesan...",
  ml_04: "Kirim",
  ml_05: "Mengunggah...",

  gl_sale_price: "harga jual",

  wd_fee: "biaya penanganan",
  wd_receipt: "Kedatangan sebenarnya",
  wd_receipt_way: "Pilih metode pembayaran yang ada",
  wd_bank_info: "Informasi bank",
  wd_bank_info_tip: "Format: Nama-Nama Bank-Nomor Kartu Bank-Rekening",
  wd_success: "Penarikan berhasil",
  wd_success_tip:
    "Permohonan penarikan Anda telah berhasil dan diharapkan tiba di akun Anda dalam waktu 24 jam",

  g_removed: "Dihapus",
  g_sale_ing: "Dijual",

  sd_01: "Produk yang direkomendasikan",
  sd_02: "Lebih",
  sd_03: "Toko yang direkomendasikan",
  sd_04: "Produk saat ini",
  sd_05: "Satu",
  sd_06: "Lihat toko",
  sd_07: "Toko Produk",
  sd_08: "Toko Aktivitas",
  sd_09: "Toko ilegal",

  mc_01: "Ditandatangani untuk",
  mc_02: "Berbayar",
  mc_03: "Waktu penandatanganan",
  mc_04: "Waktu pembayaran",
  mc_05: "Nomor pesanan",
  mc_06: "Tanda tangan",
  mc_07: "Pembeli",
  mc_08: "Alamat",
  mc_09: "Wilayah",
  mc_10: "Hubungi pembeli",

  lgi_01: "Informasi logistik",
  lgi_02: "Cocokkan gudang barang internasional",
  lgi_03: "Pengiriman ekspres sedang berlangsung",
  lgi_04: "Dalam perjalanan",
  lgi_05: "Menyampaikan",

  np_01: "Peringatan Resiko",
  np_02: "Pengumuman Sistem",
  np_03: "Saya telah membaca dan memahami",
  np_04: "Berita",

  sl_01: "Daftar sekali klik",

  w_pwd_info_01: "Penarikan kata sandi",
  w_pwd_info_02: "Ulangi kata sandi penarikan",
  w_pwd_info_03: "Silakan masukkan kembali password penarikan",
  w_pwd_info_04: "Kedua password penarikan tidak konsisten",
  w_pwd_info_05: "Kata sandi penarikan salah",
  w_pwd_info_06:
    "Jika Anda lupa kata sandi penarikan, silakan menghubungi layanan pelanggan untuk mengubah kata sandi penarikan",

  l_g_01: "Email",
  l_g_02: "Silahkan masukkan alamat email anda",
  l_g_03: "Kode verifikasi",
  l_g_04: "Silakan masukkan kode verifikasi",
  l_g_05: "Kirim",
  l_g_06: "Kata sandi masuk",
  l_g_07: "Silakan masukkan kata sandi login Anda",
  l_g_08: "Ulangi kata sandi login",
  l_g_09: "Silakan masukkan kembali kata sandi login Anda",
  l_g_10: "Daftar",
  l_g_11: "Masuk",
  l_g_12: "Sudah punya akun, login",
  l_g_13: "Masuk ke registrasi",
  l_g_14: "Keluar",
  l_g_15: "Konfirmasi untuk logout?",
  l_g_16: "Bahasa",
  l_g_17: "Kedua kata sandi login tidak konsisten",
  l_g_18:
    "Informasi pengguna tidak ada, harap periksa apakah ID yang dimasukkan benar~",
  l_g_19: "Nama Pengguna",
  l_g_20: "Silakan masukkan nama pengguna",
  l_g_21: "Masuk dan salin",
  l_g_22: "Kata sandi login yang dimasukkan salah, silakan masukkan kembali~",

  h_c_01: "Keuntungan Pesanan",
  h_c_02: "Berbayar",
  h_c_03: "Untuk dikirim",
  h_c_04: "Dikonfirmasi",
  h_c_05: "Penjualan",
  h_c_06: "Kode undangan untuk membuka toko",
  h_c_07: "Silakan masukkan kode undangan toko",
  h_c_08: "Undang teman",
  h_c_09: "Kode undangan toko salah",
  h_c_10: "Kode undangan toko telah digunakan",
  h_c_11: "Nama pengguna sudah ada",
  h_c_12:
    "Informasi pengguna tidak ada, harap periksa apakah informasi yang dimasukkan benar~",
  h_c_13: "Akun telah dibekukan",

  g_c_01: "Koleksi Saya",
  g_c_02: "Pengumpulan berhasil",

  h_g_01: "Harga pembelian produk",
  h_g_02: "Jumlah keuntungan",
  h_g_03: "Harga jual produk",

  m_s_01: "Kode verifikasi",
  m_s_02: "Silakan masukkan kode verifikasi",
  m_s_03: "Kode verifikasi error, silakan coba lagi~",
  m_s_04: "Pedagang menetap",
  m_s_05: "Nama toko sudah ada~",

  _u_l_p: "Ubah kata sandi masuk",
  _u_l_p_01: "Kata sandi lama",
  _u_l_p_02: "Silakan masukkan kata sandi lama",
  _u_l_p_03: "Kata sandi baru",
  _u_l_p_04: "Silakan masukkan kata sandi baru",
  _u_l_p_05: "Ulangi kata sandi baru",
  _u_l_p_06: "Silakan masukkan kembali kata sandi baru",
  _u_l_p_07: "*Setelah mengubah kata sandi login, Anda harus login lagi.",
  _u_l_p_08: "Kata sandi lama yang dimasukkan salah~",
};
