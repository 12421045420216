import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../views/storeHome.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/mine",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/question",
    component: () => import("../views/question.vue"),
  },
  {
    path: "/feedback",
    component: () => import("../views/feedback.vue"),
  },
  {
    path: "/newcomer",
    component: () => import("../views/newcomer.vue"),
  },
  {
    path: "/help",
    component: () => import("../views/help.vue"),
  },
  {
    path: "/service",
    component: () => import("../views/service.vue"),
  },
  {
    path: "/wallet",
    component: () => import("../views/wallet.vue"),
  },
  {
    path: "/supplier",
    component: () => import("../views/supplier.vue"),
  },
  {
    path: "/goods",
    component: () => import("../views/goodsList.vue"),
  },
  {
    path: "/goods_detail",
    component: () => import("../views/goodsDetail.vue"),
  },
  {
    path: "/showcase",
    component: () => import("../views/showcase.vue"),
  },
  {
    path: "/order",
    component: () => import("../views/order.vue"),
  },
  {
    path: "/order_detail",
    component: () => import("../views/orderDetail.vue"),
  },
  {
    path: "/completeInfo",
    component: () => import("../views/completeInfo.vue"),
  },
  {
    path: "/withdraw",
    component: () => import("../views/withdraw.vue"),
  },
  {
    path: "/ranking",
    component: () => import("../views/ranking.vue"),
  },
  {
    path: "/withdraw_order",
    component: () => import("../views/withdrawOrder.vue"),
  },
  {
    path: "/rebate_record",
    component: () => import("../views/rebateRecord.vue"),
  },
  {
    path: "/recharge",
    component: () => import("../views/recharge.vue"),
  },
  {
    path: "/set_pwd",
    component: () => import("../views/setPwd.vue"),
  },
  {
    path: "/verified",
    component: () => import("../views/verified.vue"),
  },
  {
    path: "/message_list",
    component: () => import("../views/messageList.vue"),
  },
  {
    path: "/message_chat",
    component: () => import("../views/messageChat.vue"),
  },
  {
    path: "/store_detail",
    component: () => import("../views/storeDetail.vue"),
  },
  {
    path: "/store_list",
    component: () => import("../views/storeList.vue"),
  },
  {
    path: "/store_good_list",
    component: () => import("../views/storeGoodList.vue"),
  },
  {
    path: "/notice_list",
    component: () => import("../views/noticeList.vue"),
  },
  {
    path: "/my_collect",
    component: () => import("../views/myCollect.vue"),
  },
  {
    path: "/merchants_settled",
    component: () => import("../views/merchantsSettled.vue"),
  },
  {
    path: "/update_pwd",
    component: () => import("../views/updatePwd.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(), // no-#
  history: createWebHashHistory(), // #
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 滚动位置进入新page时回到顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
