export default {
  shop_center_txt: "Centro negozi",
  score_txt: "punto",
  minute_txt: "minuto",
  wallet_txt: "portafoglio",
  today_txt: "Oggi",
  week_txt: "Questa settimana",
  month_txt: "questo mese",
  year_txt: "intero anno",
  main_indicators_txt: "Indicatori principali",
  sale_price: "prezzo scontato",
  expected_profit: "profitto atteso",
  order_count: "quantità dell`ordine",
  goods_click_count: "Numero di clic sul prodotto",
  supplier_txt: "Fornitore",
  friends_help_txt: "Aiuto degli amici",
  feedback_txt: "Feedback",
  feedback: "Feedback",
  online_service_txt_1: "Servizio clienti deposito",
  online_service_txt_2: "Consulenza aziendale",
  q_and_a_txt: "Domanda e risposta",
  open_now_txt: "Apri",
  certification_activation_code: "Codice di attivazione della certificazione",
  plz_write_invite_code: "Per favore inserisci il codice di invito",
  time_txt: "ora",
  times_txt: "ora",

  wallet_total_assets: "Totale risorse",
  balance_txt: "Saldo",
  pending_settlement_txt: "Pagamento in sospeso",
  recharge_txt: "Ricarica",
  withdraw_txt: "Ritiro",
  fund_details_txt: "Dettagli del fondo",
  no_more_txt: "Non più",
  no_data_txt: "Ancora nessun dato",
  detail_txt: "Dettagli",
  num_txt: "Numero",
  money_txt: "importo",
  remark_txt: "Osservazioni",
  no_remark_txt: "Nessuna osservazione",
  tip_txt: "Suggerimento",
  tip_content_txt:
    "A causa di emissioni di valuta sottoscritte attualmente da commercianti in vari paesi",
  need_contact_service: "Devi contattare il servizio clienti",
  contact_service: "Contatta il servizio clienti",

  feedback_title: "Dai la tua opinione",
  feedback_placeholder:
    "Per motivi di sicurezza, non includere informazioni personali",
  submit_txt: "Invia",
  sure_txt: "OK",
  submit_suc_txt: "Invio riuscito",

  loading: "Caricamento in corso...",

  newer_action: "Attività per i nuovi arrivati",
  newer_traffic_package: "Pacchetto traffico di supporto per i nuovi arrivati",
  newer_get_millions_of_products: "Ottieni milioni di prodotti",
  newer_get: "Ricevuto",
  newer_receive: "ricevi",
  newer_action_ruler: "Regole attività per i nuovi arrivati",
  newer_receive_suc: "Ricevi con successo",

  help_friends_help: "Aiuto amici",
  help_your_friends_stores: "Aiuta i tuoi amici ad aiutare",
  get_store_product_heating:
    "Ottieni il riscaldamento dei prodotti del negozio",
  help_friends_txt: "Aiuta gli amici",
  help_friends_activity_rules: "Aiuta le regole delle attività degli amici",
  assistance_details: "Dettagli assistenza",
  assistance_details_other: "Dettagli dell`assistenza ricevuta",
  team_list: "Elenco squadre",
  help_friend_name: "Aiuta il nome dell`amico",
  plz_input: "Inserisci",
  plz_input_friend_name: "Inserisci il nome del tuo amico per aiutarti",
  help_suc: "Aiuta il successo",

  online_service: "Servizio clienti online",
  online_24_hour_service: "Supporto online 24 ore su 24",
  customer_service: "Servizio clienti",
  copy_and_add_id: "Copia e aggiungi ID LINEA",
  plz_input_question_detail: "Inserisci i dettagli della tua domanda",
  harry_reply_your: "Ti risponderemo il prima possibile",
  click_copy: "Fai clic per copiare",
  copy_suc: "Copia riuscita",
  copy_fail: "Copia fallita",

  overall: "nel complesso",
  cooperate: "cooperare",
  cancel: "annullare",
  supplier_num: "Numero del fornitore",
  no_use_supplier: "Nessun fornitore disponibile",
  here_add_supplier: "Aggiungi un nuovo fornitore qui",
  add_supplier: "Aggiungi fornitore",
  plz_input_supplier_num: "Inserisci il numero del fornitore",
  add_suc: "Aggiungi con successo",
  remove_suc: "Rimosso con successo",

  new_products_launched: "Nuovi prodotti lanciati",
  profit_txt: "profitto",
  has_been_added: "Già sugli scaffali",
  on_display: "Può essere riposto sugli scaffali",
  coming_soon_txt: "Prossimamente",
  sold_txt: "Venduto",
  removed_txt: "Rimosso",

  order_detail_txt: "Dettagli dell`ordine",
  wait_express_delivery: "In attesa di consegna espressa",
  customer_info: "Informazioni sul cliente",
  order_good: "Ordina merci",
  order_num: "Numero dell`ordine",
  order_date: "data dell`ordine",
  order_content: "Riepilogo ordine",
  purchase_price_txt: "Prezzo di acquisto",
  income_txt: "Reddito",

  order_txt: "Ordine",
  pending_payment: "Pagamento in sospeso",
  ready_for_shipment: "Pronto per la spedizione",
  shipped_txt: "spedito",
  paid_txt: "Consegnato",
  good_num: "numero articolo",
  pay_and_ship: "Pagamento e spedizione",
  pay_and_ship_all: "Paga tutto",
  pay_suc: "Pagamento riuscito",
  no_order: "Ancora nessun ordine",
  no_order_tips: "Puoi monitorare i tuoi ordini e i tuoi resi qui",
  suer_order: "Conferma pagamento",
  pay_way: "Metodo di pagamento",
  wallet_pay: "pagamento con portafoglio",
  sure_pay: "Conferma pagamento",
  paying_txt: "Pagamento...",

  manage_showcase: "Gestisci vetrina",
  add_new_good: "Aggiungi nuovo prodotto",
  heating_txt: "riscaldamento",
  promotion: "promozione",
  promotion_tip: "Suggerimenti per la promozione",
  promotion_tip_txt:
    "I tuoi punti attuali sono inferiori a 200 e non puoi pagare i tuoi prodotti",
  copy_link: "Copia collegamento",
  scan_qr_code: "Scansiona il codice QR",

  about_good: "Informazioni sul prodotto",
  specification: "Specifica",
  product_overview: "Panoramica del prodotto",

  top_tips_1: "Spostare il negozio Douyin online",
  top_tips_2: "Attualmente supporta solo inviti interni",
  active_place_holeder: "Per favore inserisci",

  promotion_01: "Promozione",
  promotion_02: "Importo della promozione",
  promotion_03: "Inserisci l`importo della promozione",
  promotion_04: "Conferma promozione",
  promotion_05: "Promozione riuscita",

  bao_txt: "Tasso di esposizione",
  bal_no: "Saldo insufficiente",

  wallet_type_01: "Applicazione di ricarica",
  wallet_type_02: "Richiesta di prelievo",
  wallet_type_03: "Pagamento",
  wallet_type_04: "Detrazione di sistema",
  wallet_type_05: "Reddito di profitto",
  wallet_type_06: "Detrazione sul pagamento",
  wallet_type_07: "Consumo di distribuzione",
  wallet_type_08: "Entrate dalla promozione",
  wallet_type_09: "Aumenta ricompensa",
  wallet_type_10: "Registrati gratuitamente",
  wallet_type_11: "Premio per il fatturato",
  wallet_type_12: "Prelievo rifiutato",

  all_orders: "Tutti gli ordini",
  mine_orders: "I miei ordini",
  mine_services: "I miei servizi",
  _tzz: "Saltando...",

  info_01: "Nome",
  info_02: "E-mail",
  info_03: "Informazioni di contatto",
  info_04: "Sesso",
  info_05: "Confidenziale",
  info_06: "Uomo",
  info_07: "Femmina",
  info_08: "Data di nascita (aaaa-MM-gg)",
  info_09: "Paese",
  info_10: "città",
  info_11: "Numero della carta",
  info_12: "Tipo di carta",
  info_13: "Emittente della carta",
  info_14: "Data di scadenza (aaaa-MM-gg)",
  info_15: "Codice CVV",
  info_16: "Password di sicurezza",
  info_17: "Ripeti la password di sicurezza",
  info_18: "Inserisci nuovamente la password di sicurezza",
  info_19: "Le due password di sicurezza non sono coerenti",
  info_20: "Carta d`identità/passaporto",
  info_21: "Seleziona il tipo di immagine da caricare",
  info_22: "Carta d`identità (fronte e retro)",
  info_23: "Passaporto",
  info_24: "Patente di guida",
  info_25: "Permesso di soggiorno",
  info_26: "Certificato sanitario",
  info_27: "Carica una foto identificativa",
  info_28: "Inviato, attendi pazientemente",
  info_29: "Numero di conto",
  info_30: "Nome banca",

  wl_withdraw_money: "Importo del prelievo",
  wl_withdraw_code_201:
    "I record dei prelievi sono in fase di revisione, per favore non inviarli nuovamente",
  wl_withdraw_code_202: "I dati della carta bancaria non sono corretti",
  wl_withdraw_code_400: "Eccezione record di prelievo",
  wl_withdraw_code_204: "Password di sicurezza errata",
  wl_withdraw_code_205: "Il saldo del margine non può essere prelevato",
  wl_withdraw_code_207:
    "La valutazione a stelle dell`utente corrente non può essere ritirata, aumenta la valutazione a stelle",
  wl_withdraw_code_208:
    "La valutazione in stelle dell`utente attuale ha raggiunto il numero massimo di prelievi oggi",

  wl_withdraw_order_01: "Tutti",
  wl_withdraw_order_02: "Non revisionato",
  wl_withdraw_order_03: "Revisionato",
  wl_withdraw_order_04: "Ritiro fallito",
  wl_withdraw_order_05: "Numero",
  wl_withdraw_order_06: "Importo del prelievo",
  wl_withdraw_order_07: "Metodo di prelievo",
  wl_withdraw_order_08: "Informazioni sul prelievo",
  wl_withdraw_order_09: "Da rivedere",
  wl_withdraw_order_10: "Approvato",
  wl_withdraw_order_11: "Completato",
  wl_withdraw_order_12: "Annullato",
  wl_withdraw_order_13: "congelato",
  wl_withdraw_order_14: "Motivo del mancato ritiro",

  hl_mine_invite_code: "Il mio codice di invito",
  hl_help_friend_invite_code: "Codice di invito per aiutare un amico",
  hl_certification_activation_code: "Codice invito",
  hl_plz_write_invite_code: "Inserisci il codice di invito",
  hl_help_code_202: "Codice di invito all`aiuto errato",
  hl_help_code_203:
    "Il codice di invito all`aiuto non può essere associato a se stesso",
  hl_help_code_205:
    "Il numero massimo di assistenza è stato raggiunto e non è consentita ulteriore assistenza",
  hl_help_code_206: "L`amico attuale ha già aiutato e non può aiutare di nuovo",
  gl_visits: "Visite",

  _in_sp: "Classifica del prodotto",
  _r_k01: "Classifica",
  _r_k02: "Prodotto",
  _r_k03: "calore",

  _zw_ph: "Ancora nessuna informazione sulla classificazione del prodotto!",
  _zf_201: "Stato dell`ordine anomalo",
  _zf_202: "Saldo patrimoniale insufficiente",
  _zf_203: "Pagamento non riuscito",
  _zf_204:
    "Le transazioni del commerciante sono state congelate, contatta il servizio clienti",

  _withdraw_order_txt: "Ritira l`ordine",

  shop_num: "numero del negozio",
  plz_input_shop_num: "Inserisci il numero del negozio",
  shop_num_error: "Il numero del negozio non è corretto",
  supplier_num_error: "Il numero del fornitore non è corretto",
  supplier_num_has_bound: "Il numero del fornitore è stato associato",

  sc_day_sale: "Vendite giornaliere",
  sc_week_sale: "Vendite settimanali",
  hl_mine_shop: "Il mio negozio",
  hl_shop_name: "Nome del negozio",
  hl_input_shop_name: "Inserisci il nome del negozio",
  hl_add_shop_name: "Aggiungi il nome del negozio",
  hl_update_shop_name: "Modifica il nome del negozio",
  hl_update_suc: "Modifica riuscita",

  hl_rabate_01: "Commissione di livello inferiore",
  hl_rabate_02: "Registrazione sconto",
  hl_rabate_03: "Rapporto di sconto",
  hl_rabate_04: "Importo totale dello sconto",
  hl_rabate_05: "Vendite totali",
  hl_rabate_06: "Importo delle vendite",
  hl_rabate_07: "Importo del rimborso",
  hl_rabate_08: "Ora di registrazione",
  hl_rabate_09: "Assistenza di livello 1",
  hl_rabate_10: "Assistenza di secondo livello",
  hl_rabate_11: "Assistenza di livello 3",

  dd_order_01: "Annulla ordine",
  dd_order_02: "Reso",
  dd_order_03: "Restituzione",
  dd_order_04: "Restituito",

  _t_p_qr: "Conferma",
  _t_p_txt01:
    "Se annullare l`ordine? L`annullamento dell`ordine è irreversibile e non può essere trovato!!!",
  _t_p_txt02: "Conferma se questo ordine deve essere restituito!",
  _t_p_txt03: "Annullamento riuscito",
  _t_p_txt04: "La richiesta di reso è stata inviata",

  showcase_01: "Effettua un ordine",
  showcase_02: "Ordine effettuato con successo",
  showcase_03: "Inserisci il tuo ID amico",

  goods_price: "prezzo",

  u_pwd_tips:
    "Contatta il servizio clienti per modificare la password di sicurezza",

  g_to_be_purchased: "Da acquistare",
  g_purchasing_and_shipping: "Acquisto e spedizione",
  g_suer_purchase_order: "Conferma acquisto",

  hl_star_rating_text_1: "Commerciante a una stella",
  hl_star_rating_text_2: "Commerciante a due stelle",
  hl_star_rating_text_3: "Commerciante Samsung",
  hl_star_rating_text_4: "Commerciante a quattro stelle",
  hl_star_rating_text_5: "Commerciante a cinque stelle",

  hl_auth_tips:
    "L`autenticazione del nome reale è stata inviata ed è in fase di revisione...",

  ml_01: "Non letto",
  ml_02: "Leggi",
  ml_03: "Contenuto del messaggio...",
  ml_04: "Invia",
  ml_05: "Caricamento in corso...",

  gl_sale_price: "prezzo di vendita",

  wd_fee: "commissione di gestione",
  wd_receipt: "Arrivo effettivo",
  wd_receipt_way: "Seleziona un metodo di pagamento esistente",
  wd_bank_info: "Informazioni bancarie",
  wd_bank_info_tip: "Formato: Nome-Nome banca-Conto Bancario-Numero carta",
  wd_success: "Ritiro riuscito",
  wd_success_tip:
    "La tua richiesta di prelievo ha avuto esito positivo e dovrebbe arrivare sul tuo conto entro 24 ore",

  g_removed: "Rimosso",
  g_sale_ing: "In vendita",

  sd_01: "Prodotti consigliati",
  sd_02: "altro",
  sd_03: "Negozio consigliato",
  sd_04: "Prodotto attuale",
  sd_05: "uno",
  sd_06: "Visualizza negozio",
  sd_07: "Negozia prodotti",
  sd_08: "Negozio di attività",
  sd_09: "Negozio illegale",

  mc_01: "Firmato per",
  mc_02: "Pagato",
  mc_03: "Ora della firma",
  mc_04: "Tempo di pagamento",
  mc_05: "Numero ordine",
  mc_06: "Firma",
  mc_07: "Acquirente",
  mc_08: "Indirizzo",
  mc_09: "Regione",
  mc_10: "Contatta l`acquirente",

  lgi_01: "Informazioni logistiche",
  lgi_02: "Abbina magazzino merci internazionale",
  lgi_03: "Consegna espressa in corso",
  lgi_04: "In transito",
  lgi_05: "Consegna",

  np_01: "Avviso di rischio",
  np_02: "Annuncio di sistema",
  np_03: "Ho letto e compreso",
  np_04: "Notizie",

  sl_01: "Elenco con un clic",

  w_pwd_info_01: "Password di prelievo",
  w_pwd_info_02: "Ripeti la password di prelievo",
  w_pwd_info_03: "Inserisci nuovamente la password di prelievo",
  w_pwd_info_04: "Le due password di prelievo non sono coerenti",
  w_pwd_info_05: "Password di prelievo errata",
  w_pwd_info_06:
    "Se dimentichi la password di prelievo, contatta il servizio clienti per modificare la password di prelievo",

  l_g_01: "E-mail",
  l_g_02: "Inserisci il tuo indirizzo email",
  l_g_03: "Codice di verifica",
  l_g_04: "Inserisci il codice di verifica",
  l_g_05: "Invia",
  l_g_06: "Password di accesso",
  l_g_07: "Inserisci la password di accesso",
  l_g_08: "Ripeti la password di accesso",
  l_g_09: "Inserisci nuovamente la password di accesso",
  l_g_10: "Registrati",
  l_g_11: "Accedi",
  l_g_12: "Hai già un account, accedi",
  l_g_13: "Vai alla registrazione",
  l_g_14: "Esci",
  l_g_15: "Confermi per uscire?",
  l_g_16: "Lingua",
  l_g_17: "Le due password di accesso non sono coerenti",
  l_g_18:
    "Le informazioni sull`utente non esistono, controlla se l`ID è inserito correttamente~",
  l_g_19: "Nome utente",
  l_g_20: "Inserisci il nome utente",
  l_g_21: "Accedi e copia",
  l_g_22:
    "La password di accesso è stata inserita in modo errato, per favore inseriscila nuovamente~",

  h_c_01: "Profitto dell`ordine",
  h_c_02: "Pagato",
  h_c_03: "Da spedire",
  h_c_04: "Confermato",
  h_c_05: "Vendite",
  h_c_06: "Codice di invito per l`apertura di un negozio",
  h_c_07: "Inserisci il codice di invito del negozio",
  h_c_08: "Invita amici",
  h_c_09: "Codice di invito al negozio errato",
  h_c_10: "Il codice di invito del negozio è stato utilizzato",
  h_c_11: "Il nome utente esiste già",
  h_c_12:
    "Le informazioni sull`utente non esistono, controlla se le informazioni sono state inserite correttamente~",
  h_c_13: "Il conto è stato congelato",

  g_c_01: "La mia collezione",
  g_c_02: "Ritiro riuscito",

  h_g_01: "Prezzo di acquisto del prodotto",
  h_g_02: "Importo del profitto",
  h_g_03: "Prezzo di vendita del prodotto",

  m_s_01: "Codice di verifica",
  m_s_02: "Inserisci il codice di verifica",
  m_s_03: "Errore del codice di verifica, riprova~",
  m_s_04: "Il commerciante si è sistemato",
  m_s_05: "Il nome del negozio esiste già~",

  _u_l_p: "Cambia password di accesso",
  _u_l_p_01: "Vecchia password",
  _u_l_p_02: "Inserisci la vecchia password",
  _u_l_p_03: "Nuova password",
  _u_l_p_04: "Inserisci una nuova password",
  _u_l_p_05: "Ripeti la nuova password",
  _u_l_p_06: "Inserisci nuovamente la nuova password",
  _u_l_p_07:
    "*Dopo aver modificato la password di accesso, è necessario effettuare nuovamente l'accesso.",
  _u_l_p_08: "La vecchia password è stata inserita in modo errato~",
};
