export default {
  shop_center_txt: "商铺中心",
  score_txt: "分",
  minute_txt: "分",
  wallet_txt: "钱包",
  today_txt: "本日",
  week_txt: "本周",
  month_txt: "本月",
  year_txt: "整年",
  main_indicators_txt: "主要指标",
  sale_price: "销售价格",
  expected_profit: "预期利润",
  order_count: "订单数量",
  goods_click_count: "产品点击数",
  supplier_txt: "供应商",
  friends_help_txt: "朋友助力",
  feedback_txt: "反馈",
  feedback: "意见反馈",
  online_service_txt_1: "入金客服",
  online_service_txt_2: "业务咨询",
  q_and_a_txt: "问答",
  open_now_txt: "开启",
  certification_activation_code: "认证激活码",
  plz_write_invite_code: "请填写邀请码",
  time_txt: "时",
  times_txt: "时间",

  wallet_total_assets: "总财产",
  balance_txt: "余额",
  pending_settlement_txt: "待结算",
  recharge_txt: "充值",
  withdraw_txt: "提现",
  fund_details_txt: "资金明细",
  no_more_txt: "没有更多了",
  no_data_txt: "暂无数据",
  detail_txt: "明细详情",
  num_txt: "编号",
  money_txt: "金额",
  remark_txt: "备注",
  no_remark_txt: "无备注",
  tip_txt: "提示",
  tip_content_txt: "由于各国商户承销币种问题，目前",
  need_contact_service: "需联系客服",
  contact_service: "联系客服",

  feedback_title: "反馈你的意见",
  feedback_placeholder: "出于安全考虑，请勿包含个人信息",
  submit_txt: "提交",
  sure_txt: "确定",
  submit_suc_txt: "提交成功",

  loading: "加载中...",

  newer_action: "新人活动",
  newer_traffic_package: "新人扶持流量包",
  newer_get_millions_of_products: "领取百万商品",
  newer_get: "已领取",
  newer_receive: "领取",
  newer_action_ruler: "新人活动规则",
  newer_receive_suc: "领取成功",

  help_friends_help: "好友助力",
  help_your_friends_stores: "帮助您的好友助力",
  get_store_product_heating: "获取店铺商品加热",
  help_friends_txt: "助力朋友",
  help_friends_activity_rules: "助力好友活动规则",
  assistance_details: "助力明细",
  assistance_details_other: "被助力明细",
  team_list: "团队列表",
  help_friend_name: "助力好友名",
  plz_input: "请输入",
  plz_input_friend_name: "请输入助力好友名",
  help_suc: "助力成功",

  online_service: "在线客服",
  online_24_hour_service: "24小时在线支持",
  customer_service: "客户服务",
  copy_and_add_id: "复制并添加LINE ID",
  plz_input_question_detail: "请输入你的问题的详细信息",
  harry_reply_your: "我们会尽快回复你",
  click_copy: "点击复制",
  copy_suc: "复制成功",
  copy_fail: "复制失败",

  overall: "整体",
  cooperate: "合作",
  cancel: "取消",
  supplier_num: "供货商编号",
  no_use_supplier: "没有可用的供应商",
  here_add_supplier: "在这里添加新供应商",
  add_supplier: "添加供货商",
  plz_input_supplier_num: "请输入供货商编号",
  add_suc: "添加成功",
  remove_suc: "下架成功",

  new_products_launched: "上架新商品",
  profit_txt: "利润",
  has_been_added: "已上架",
  on_display: "可上架",
  coming_soon_txt: "上架中",
  sold_txt: "已售出",
  removed_txt: "已下架",

  order_detail_txt: "订单详情",
  waiting_express_delivery: "等待快递揽收",
  customer_info: "客户信息",
  order_good: "订单商品",
  order_num: "订单号",
  order_date: "订单日期",
  order_content: "订单摘要",
  purchase_price_txt: "进价",
  income_txt: "收入",

  order_txt: "订单",
  pending_payment: "待付款",
  ready_for_shipment: "准备发货",
  shipped_txt: "已发货",
  paid_txt: "已交付",
  good_num: "商品编号",
  pay_and_ship: "付款与发货",
  pay_and_ship_all: "全部付款",
  pay_suc: "支付成功",
  no_order: "暂无订单",
  no_order_tips: "你可以在这里追踪你的订单和退货",
  suer_order: "确认付款",
  pay_way: "支付方式",
  wallet_pay: "钱包支付",
  sure_pay: "确认支付",
  paying_txt: "支付中...",

  manage_showcase: "管理橱窗",
  add_new_good: "添加新商品",
  heating_txt: "加热",
  promotion: "推广",
  promotion_tip: "推广提示",
  promotion_tip_txt: "您当前的积分低于200，您无法为你的产品付款",
  copy_link: "复制链接",
  scan_qr_code: "扫描二维码",

  about_good: "关于商品",
  specification: "规格",
  product_overview: "产品概览",

  top_tips_1: "抖音店铺线上转线上",
  top_tips_2: "目前仅支持内部邀请",
  active_place_holeder: "请输入",

  promotion_01: "推广",
  promotion_02: "推广金额",
  promotion_03: "请输入推广金额",
  promotion_04: "确认推广",
  promotion_05: "推广成功",

  bao_txt: "曝光率",
  bal_no: "余额不足",

  wallet_type_01: "充值申请",
  wallet_type_02: "提现申请",
  wallet_type_03: "支付货款",
  wallet_type_04: "系统扣款",
  wallet_type_05: "利润收入",
  wallet_type_06: "付款扣除",
  wallet_type_07: "投放消耗",
  wallet_type_08: "推广收益",
  wallet_type_09: "助力奖励",
  wallet_type_10: "注册赠送",
  wallet_type_11: "营业额奖励",
  wallet_type_12: "提现驳回",

  all_orders: "全部订单",
  mine_orders: "我的订单",
  mine_services: "我的服务",
  _tzz: "跳转中...",

  info_01: "姓名",
  info_02: "邮箱",
  info_03: "联系方式",
  info_04: "性别",
  info_05: "保密",
  info_06: "男",
  info_07: "女",
  info_08: "出生日期(yyyy-MM-dd)",
  info_09: "国家",
  info_10: "城市",
  info_11: "卡号",
  info_12: "卡类型",
  info_13: "发卡机构",
  info_14: "到期日(yyyy-MM-dd)",
  info_15: "CVV码",
  info_16: "安全密码",
  info_17: "重复安全密码",
  info_18: "请再次输入安全密码",
  info_19: "2次安全密码不一致",
  info_20: "身份证/护照",
  info_21: "选择上传图片类型",
  info_22: "身份证(正反面)",
  info_23: "护照",
  info_24: "驾驶证",
  info_25: "居住证",
  info_26: "健康证",
  info_27: "请上传证件照片",
  info_28: "已提交，请耐心等待",
  info_29: "账户号码",
  info_30: "银行名称",

  wl_withdraw_money: "提现金额",
  wl_withdraw_code_201: "提现记录审核中，请勿重复提交",
  wl_withdraw_code_202: "银行卡信息有误",
  wl_withdraw_code_400: "提现记录异常",
  wl_withdraw_code_204: "安全密码错误",
  wl_withdraw_code_205: "保证金余额不能提现",
  wl_withdraw_code_207: "当前用户星级无法提现，请提升星级",
  wl_withdraw_code_208: "当前用户星级今日提现已达最大提现次数",

  wl_withdraw_order_01: "全部",
  wl_withdraw_order_02: "未审核",
  wl_withdraw_order_03: "已审核",
  wl_withdraw_order_04: "提现失败",
  wl_withdraw_order_05: "编号",
  wl_withdraw_order_06: "提现金额",
  wl_withdraw_order_07: "提现方式",
  wl_withdraw_order_08: "提现信息",
  wl_withdraw_order_09: "待审核",
  wl_withdraw_order_10: "审核通过",
  wl_withdraw_order_11: "已完成",
  wl_withdraw_order_12: "已取消",
  wl_withdraw_order_13: "已冻结",
  wl_withdraw_order_14: "提现失败理由",

  hl_mine_invite_code: "我的邀请码",
  hl_help_friend_invite_code: "助力好友邀请码",
  hl_certification_activation_code: "邀请码",
  hl_plz_write_invite_code: "请输入邀请码",
  hl_help_code_202: "助力邀请码错误",
  hl_help_code_203: "助力邀请码不能绑定自已",
  hl_help_code_205: "已达助力最大数量，不可再助力",
  hl_help_code_206: "当前好友已助力过，不能再次助力",
  gl_visits: "访问量",

  _in_sp: "商品排行",
  _r_k01: "排名",
  _r_k02: "商品",
  _r_k03: "热度",

  _zw_ph: "暂无商品排行信息！",
  _zf_201: "订单状态异常",
  _zf_202: "资产余额不足",
  _zf_203: "支付失败",
  _zf_204: "商户交易已冻结，请联系客服",

  _withdraw_order_txt: "提现订单",

  shop_num: "店铺编号",
  plz_input_shop_num: "请输入店铺编号",
  shop_num_error: "店铺编号有误",
  supplier_num_error: "供货商编号有误",
  supplier_num_has_bound: "供货商编号已绑定",

  sc_day_sale: "日销量",
  sc_week_sale: "周销量",
  hl_mine_shop: "我的店铺",
  hl_shop_name: "店铺名称",
  hl_input_shop_name: "请输入店铺名称",
  hl_add_shop_name: "添加店铺名称",
  hl_update_shop_name: "修改店铺名称",
  hl_update_suc: "修改成功",

  hl_rabate_01: "下级返佣",
  hl_rabate_02: "返佣记录",
  hl_rabate_03: "返佣比例",
  hl_rabate_04: "返佣总额",
  hl_rabate_05: "销售总额",
  hl_rabate_06: "销售金额",
  hl_rabate_07: "返佣金额",
  hl_rabate_08: "注册时间",
  hl_rabate_09: "一级助力",
  hl_rabate_10: "二级助力",
  hl_rabate_11: "三级助力",

  dd_order_01: "取消订单",
  dd_order_02: "退货",
  dd_order_03: "退货中",
  dd_order_04: "已退货",

  _t_p_qr: "确认",
  _t_p_txt01: "是否取消订单，取消订单不可撤回且无法查找！！！",
  _t_p_txt02: "请确认该订单是否需要进行退货处理！",
  _t_p_txt03: "取消成功",
  _t_p_txt04: "退货申请已提交",

  showcase_01: "下单",
  showcase_02: "下单成功",
  showcase_03: "请输入好友ID",

  goods_price: "价格",

  u_pwd_tips: "修改安全密码请联系客服",

  g_to_be_purchased: "待采购",
  g_purchasing_and_shipping: "采购并发货",
  g_suer_purchase_order: "确认采购",

  hl_star_rating_text_1: "一星商户",
  hl_star_rating_text_2: "二星商户",
  hl_star_rating_text_3: "三星商户",
  hl_star_rating_text_4: "四星商户",
  hl_star_rating_text_5: "五星商户",

  hl_auth_tips: "实名认证已提交，正在审核中...",

  ml_01: "未读",
  ml_02: "已读",
  ml_03: "消息内容...",
  ml_04: "发送",
  ml_05: "上传中...",

  gl_sale_price: "售价",

  wd_fee: "手续费",
  wd_receipt: "实际到账",
  wd_receipt_way: "选择已有收款方式",
  wd_bank_info: "银行信息",
  wd_bank_info_tip: "格式：姓名-银行名称-开户行-卡号",
  wd_success: "提现成功",
  wd_success_tip: "您的提现申请已成功，预计24小时内到达您的账户",

  g_removed: "下架",
  g_sale_ing: "在售中",

  sd_01: "推荐产品",
  sd_02: "更多",
  sd_03: "推荐店铺",
  sd_04: "当前产品",
  sd_05: "个",
  sd_06: "查看店铺",
  sd_07: "店铺产品",
  sd_08: "活动店铺",
  sd_09: "违规店铺",

  mc_01: "已签收",
  mc_02: "已支付",
  mc_03: "签收时间",
  mc_04: "支付时间",
  mc_05: "订单编号",
  mc_06: "签收人",
  mc_07: "购买人",
  mc_08: "地址",
  mc_09: "地区",
  mc_10: "联系买家",

  lgi_01: "物流信息",
  lgi_02: "匹配国际货品仓库",
  lgi_03: "快递收揽中",
  lgi_04: "运输中",
  lgi_05: "配送中",

  np_01: "风险提示",
  np_02: "系统公告",
  np_03: "我已阅读,并知晓",
  np_04: "新闻资讯",

  sl_01: "一键上架",

  w_pwd_info_01: "提现密码",
  w_pwd_info_02: "重复提现密码",
  w_pwd_info_03: "请再次输入提现密码",
  w_pwd_info_04: "2次提现密码不一致",
  w_pwd_info_05: "提现密码错误",
  w_pwd_info_06: "如忘记提现密码，请联系客服修改提现密码",

  l_g_01: "邮箱",
  l_g_02: "请输入邮箱",
  l_g_03: "验证码",
  l_g_04: "请输入验证码",
  l_g_05: "发送",
  l_g_06: "登录密码",
  l_g_07: "请输入登录密码",
  l_g_08: "重复登录密码",
  l_g_09: "请再次输入登录密码",
  l_g_10: "注册",
  l_g_11: "登录",
  l_g_12: "已有账号，去登录",
  l_g_13: "去注册",
  l_g_14: "退出登录",
  l_g_15: "确认退出登录?",
  l_g_16: "语言",
  l_g_17: "2次登录密码不一致",
  l_g_18: "用户信息不存在,请检查ID是否输入正确~",
  l_g_19: "用户名",
  l_g_20: "请输入用户名",
  l_g_21: "登录并复制",
  l_g_22: "登录密码输入有误，请重新输入~",

  h_c_01: "订单收益",
  h_c_02: "已付款",
  h_c_03: "待发货",
  h_c_04: "已确认",
  h_c_05: "销售额",
  h_c_06: "开店邀请码",
  h_c_07: "请输入开店邀请码",
  h_c_08: "邀请好友",
  h_c_09: "开店邀请码错误",
  h_c_10: "开店邀请码已使用",
  h_c_11: "用户名已存在",
  h_c_12: "用户信息不存在,请检查信息是否输入正确~",
  h_c_13: "账户已冻结",

  g_c_01: "我的收藏",
  g_c_02: "收藏成功",

  h_g_01: "商品进价",
  h_g_02: "利润金额",
  h_g_03: "商品售价",

  m_s_01: "验证码",
  m_s_02: "请输入验证码",
  m_s_03: "验证码错误，请重试~",
  m_s_04: "商家入驻",
  m_s_05: "店铺名称已存在~",

  _u_l_p: "修改登录密码",
  _u_l_p_01: "旧密码",
  _u_l_p_02: "请输入旧密码",
  _u_l_p_03: "新密码",
  _u_l_p_04: "请输入新密码",
  _u_l_p_05: "重复新密码",
  _u_l_p_06: "请再次输入新密码",
  _u_l_p_07: "*修改登录密码后，需要重新登录。",
  _u_l_p_08: "旧密码输入有误~",
};
